<template>
  <v-container fluid class="container-vp">
    <v-row :class="is_json ? 'no-gutters' : ''">
      <v-col
        :class="is_json ? 'mr-2' : ''"
        v-show="is_json && left_container && showSideContainers"
      >
        <div class="right-container" style="height: 535px">
          <v-card elevation="2" style="height: 535px">
            <v-tabs v-model="tabs" class="table_tabs_vp" height="25">
              <v-tab>Inference</v-tab>
              <v-tab>Animals</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item>
                <v-container fluid class="container-vp">
                  <v-card-title class="justify-start inference-title">
                    Calving
                  </v-card-title>
                  <v-container
                    fluid
                    class="toggle-switches-events container-vp"
                  >
                    <v-switch
                      :disabled="!calvingEventPresent"
                      v-model="calvingEvents"
                    >
                      <template #label>
                        <span>Tail Up</span>
                        <span
                          class="d-inline-block"
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background-color: rgb(60, 102, 104);
                            margin-left: 40px;
                            border-radius: 50%;
                          "
                        ></span>
                      </template>
                    </v-switch>
                  </v-container>
                </v-container>
                <v-container fluid class="container-vp label-container">
                  <v-card-title class="justify-start inference-title">
                    Estrus
                  </v-card-title>
                  <v-container
                    fluid
                    class="toggle-switches-events container-vp"
                  >
                    <v-switch
                      :disabled="!standingHeatEventPresent"
                      v-model="standingHeatEvents"
                    >
                      <template #label>
                        <span>Heat</span>
                        <span
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background-color: #a0215f;
                            margin-left: 54px;
                            border-radius: 50%;
                          "
                        >
                        </span>
                      </template>
                    </v-switch>
                  </v-container>
                </v-container>
                <v-container fluid class="toggle-switches-events container-vp">
                  <v-switch
                    :disabled="!chinRestingEventPresent"
                    v-model="chinRestingEvents"
                  >
                    <template #label>
                      <span>Chin Rest</span>
                      <span
                        class="d-inline-block"
                        style="
                          display: inline-block;
                          width: 10px;
                          height: 10px;
                          background-color: rgb(116, 165, 169);
                          margin-left: 20px;
                          border-radius: 50%;
                        "
                      ></span>
                    </template>
                  </v-switch>
                </v-container>
                <v-container fluid class="toggle-switches-events container-vp">
                  <v-switch
                    :disabled="!mountingEventPresent"
                    v-model="mountingEvents"
                  >
                    <template #label>
                      <span>Mounting</span>
                      <span
                        class="d-inline-block"
                        style="
                          display: inline-block;
                          width: 10px;
                          height: 10px;
                          background-color: rgb(141, 82, 50);
                          margin-left: 20px;
                          border-radius: 50%;
                        "
                      ></span>
                    </template>
                  </v-switch>
                </v-container>
                <v-container fluid class="container-vp label-container">
                  <v-card-title class="justify-start inference-title">
                    Traits
                  </v-card-title>
                  <v-container
                    fluid
                    class="toggle-switches-events container-vp"
                  >
                    <v-switch
                      :disabled="!isActivityPresent"
                      v-model="isActivity"
                    >
                      <template #label>
                        <span>Activity</span>
                        <span
                          class="d-inline-block"
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background-color: rgb(251, 172, 45);
                            margin-left: 35px;
                            border-radius: 50%;
                          "
                        ></span>
                      </template>
                    </v-switch>
                  </v-container>
                </v-container>
                <v-container fluid class="toggle-switches-events container-vp">
                  <v-switch :disabled="!isGrazingPresent" v-model="isGrazing">
                    <template #label>
                      <span>Grazing</span>
                      <span
                        class="d-inline-block"
                        style="
                          display: inline-block;
                          width: 10px;
                          height: 10px;
                          background-color: rgb(6, 104, 39);
                          margin-left: 33px;
                          border-radius: 50%;
                        "
                      ></span>
                    </template>
                  </v-switch>
                </v-container>
                <v-container fluid class="toggle-switches-events container-vp">
                  <v-switch :disabled="!isGenderPresent" v-model="isGender">
                    <template #label>
                      <span>Gender</span>
                      <span
                        class="d-inline-block"
                        style="
                          display: inline-block;
                          width: 10px;
                          height: 10px;
                          background-color: purple;
                          margin-left: 36px;
                          border-radius: 50%;
                        "
                      ></span>
                    </template>
                  </v-switch>
                </v-container>
                <v-container fluid class="toggle-switches-events container-vp">
                  <v-switch :disabled="!isAgePresent" v-model="isAge">
                    <template #label>
                      <span>Age</span>
                      <span
                        class="d-inline-block"
                        style="
                          display: inline-block;
                          width: 10px;
                          height: 10px;
                          background-color: rgb(60, 102, 104);
                          margin-left: 60px;
                          border-radius: 50%;
                        "
                      ></span>
                    </template>
                  </v-switch>
                </v-container>
                <v-container fluid class="container-vp label-container">
                  <v-card-title class="justify-start inference-title">
                    Hunters
                  </v-card-title>
                  <v-container
                    fluid
                    class="toggle-switches-events container-vp"
                  >
                    <v-switch
                      :disabled="!predatorEventPresent"
                      v-model="predatorEvents"
                    >
                      <template #label>
                        <span>Predators</span>
                        <span
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background-color: rgb(136, 53, 40);
                            margin-left: 19px;
                            border-radius: 50%;
                          "
                        >
                        </span>
                      </template>
                    </v-switch>
                  </v-container>
                </v-container>
                <v-container fluid class="container-vp label-container">
                  <v-card-title class="justify-start inference-title">
                    Thermal
                  </v-card-title>
                  <v-container
                    fluid
                    class="toggle-switches-events container-vp"
                  >
                    <v-switch v-model="toggleHeatMap">
                      <template #label>
                        <span>Heat Maps</span>
                      </template>
                    </v-switch>
                  </v-container>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <span v-if="!filteredTags.length">
                  <div style="margin-top: 85%; margin-left: 10%">
                    <v-img
                      width="150px"
                      height="150px"
                      src="../../assets/BETSY_wave_smile.png"
                      contain
                    ></v-img>
                  </div>
                  <v-spacer></v-spacer>
                  <div
                    style="
                      font-size: 14px;
                      letter-spacing: 2px;
                      color: #0090a4;
                      text-align: center;
                    "
                  >
                    ANIMAL
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      letter-spacing: 2px;
                      color: #0090a4;
                      text-align: center;
                    "
                  >
                    IDENTIFICATION
                  </div>
                  <div
                    style="
                      font-size: 14px;
                      letter-spacing: 2px;
                      color: #0090a4;
                      text-align: center;
                    "
                  >
                    PENDING
                  </div>
                </span>
                <span v-else>
                  <div v-if="displayingOneAnimal">
                    <v-card
                      elevation="2"
                      height="80%"
                      style="padding: 5px 5px 0px 5px"
                    >
                      <v-overlay
                        opacity="0"
                        absolute
                        style="justify-content: start; align-items: start"
                      >
                        <div class="image_selector_ac">
                          <span
                            class="image_selector_icons_ac"
                            style="margin-left: 135%; margin-top: 7%"
                          >
                            <v-icon
                              medium
                              style="color: red"
                              @click="showOriginal"
                              >mdi-close</v-icon
                            ></span
                          >
                          <span
                            class="image_selector_icons_ac"
                            style="margin-left: 5em; margin-top: 12em"
                            ><v-icon
                              medium
                              :color="
                                front_image_select == true ? 'primary' : null
                              "
                              @click="toggle_animal_image()"
                              >mdi-chevron-left</v-icon
                            ><v-icon
                              medium
                              :color="
                                front_image_select == false ? 'primary' : null
                              "
                              @click="toggle_animal_image()"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </div>
                      </v-overlay>
                      <v-img
                        ref="animalImage"
                        style="border-radius: 4px"
                        aspect-ratio="1"
                        @error="() => handleImageError(displayCurrentAnimal)"
                        :src="
                          front_image_select
                            ? displayCurrentAnimal.imageUrl
                            : displayCurrentAnimal.imageUrlBody
                        "
                      >
                        <v-overlay
                          opacity="0"
                          absolute
                          style="justify-content: start; align-items: start"
                        >
                          <v-chip
                            style="color: white; font-weight: 500"
                            small
                            :color="
                              displayCurrentAnimal.tag_color &&
                              displayCurrentAnimal.tag_color != 'undefined'
                                ? 'oc_' +
                                  displayCurrentAnimal.tag_color.toLowerCase() +
                                  ' darken-1'
                                : 'grey darken-1'
                            "
                          >
                            {{
                              displayCurrentAnimal.tag_label
                                ? displayCurrentAnimal.tag_label
                                : "UND"
                            }}
                          </v-chip>
                        </v-overlay>
                      </v-img>

                      <div
                        style="
                          font-size: 15px;
                          letter-spacing: 2px;
                          text-transform: uppercase;
                          margin-left: 10px;
                          margin-top: 10px;
                          color: #0090a4;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        {{
                          displayCurrentAnimal && displayCurrentAnimal.name
                            ? displayCurrentAnimal.name
                            : "Unknown"
                        }}
                      </div>
                      <div
                        style="
                          margin-top: 10px;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      >
                        <v-select
                          hide-details
                          outlined
                          append-icon=""
                          readonly
                          label="Species"
                          dense
                          :items="[
                            { value: 'BISON', text: 'Bison' },
                            { value: 'CAMEL', text: 'Camel' },
                            { value: 'CAT', text: 'Cat' },
                            { value: 'CATTLE', text: 'Cattle' },
                            { value: 'DOG', text: 'Dog' },
                            { value: 'ELK', text: 'Elk' },
                            { value: 'GOAT', text: 'Goat' },
                            { value: 'HORSE', text: 'Horse' },
                            { value: 'SHEEP', text: 'Sheep' },
                            { value: 'SWINE', text: 'Swine' },
                            { value: 'OTHER', text: 'Other' },
                          ]"
                          v-model="displayCurrentAnimal.species"
                        />
                      </div>
                      <div
                        style="
                          margin-top: 10px;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      >
                        <v-text-field
                          hide-details
                          outlined
                          readonly
                          label="Breed"
                          dense
                          v-model="displayCurrentAnimal.breed"
                        />
                      </div>
                      <div
                        style="
                          margin-top: 10px;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      >
                        <v-text-field
                          hide-details
                          outlined
                          readonly
                          label="Color"
                          dense
                          v-model="displayCurrentAnimal.color"
                        />
                      </div>
                      <div
                        style="
                          margin-top: 10px;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      >
                        <v-select
                          hide-details
                          outlined
                          append-icon=""
                          readonly
                          label="Sex"
                          dense
                          :items="[
                            { value: 'MALE', text: 'Male' },
                            { value: 'FEMALE', text: 'Female' },
                            { value: 'OTHER', text: 'Other' },
                          ]"
                          v-model="displayCurrentAnimal.sex"
                        />
                      </div>
                      <div
                        style="
                          margin-top: 10px;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      >
                        <v-select
                          hide-details
                          outlined
                          append-icon=""
                          readonly
                          label="Classification"
                          dense
                          hide-selected
                          :items="[
                            { value: 'BULL', text: 'Bull' },
                            { value: 'CALF', text: 'Calf' },
                            { value: 'COW', text: 'Cow' },
                            { value: 'FREEMARTIN', text: 'Freemartin' },
                            { value: 'HEIFER', text: 'Heifer' },
                            { value: 'STEER', text: 'Steer' },
                            { value: 'OTHER', text: 'Other' },
                          ]"
                          v-model="displayCurrentAnimal.classification"
                        />
                      </div>
                    </v-card>
                  </div>

                  <div
                    :class="{ 'custom-style': filteredTags.length > 0 }"
                    v-else
                  >
                    <v-card
                      v-for="(animal, index) in filteredTags"
                      :key="index"
                      @click="displayAnimal(animal)"
                      style="height: 101px; margin: 1%"
                      hover
                      class="card-item"
                    >
                      <v-container>
                        <v-overlay
                          opacity="0"
                          absolute
                          style="
                            justify-content: start;
                            align-items: start;
                            pointer-events: none;
                            margin-top: 5px;
                          "
                        >
                          <v-chip
                            style="
                              color: white;
                              font-weight: 500;
                              margin-bottom: -3px;
                            "
                            small
                            :color="
                              animal.tag_label &&
                              animal.tag_color &&
                              animal.tag_color != 'UNDEFINED'
                                ? 'oc_' +
                                  animal.tag_color.toLowerCase() +
                                  ' darken-1'
                                : 'grey darken-1'
                            "
                          >
                            {{ animal.tag_label ? animal.tag_label : "UND" }}
                          </v-chip>
                        </v-overlay>
                        <v-row
                          dense
                          style="margin: 0 !important; padding: 0 !important"
                        >
                          <v-col cols="5">
                            <v-img
                              ref="animalCardImage"
                              height="76px"
                              class="ma-1"
                              aspect-ratio="1"
                              @error="() => handleImageError(animal)"
                              :src="animal.imageUrl"
                            ></v-img>
                          </v-col>
                          <v-col cols="7">
                            <div class="text-caption">
                              {{ animal.color && animal.color }}
                            </div>
                            <div class="text-caption">
                              {{ animal.dob && animal.dob }}
                            </div>
                            <div class="text-caption">
                              {{ animal.sex && formatString(animal.sex) }}
                            </div>
                            <div class="text-caption">
                              {{
                                animal.classification &&
                                formatString(animal.classification)
                              }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </div>
                </span>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-col>
      <v-col>
        <div class="middle">
          <div>
            <div class="container-vp">
              <div class="video-container paused vjs-control-bar">
                <img class="thumbnail-img" style="z-index: 10" />
                <img class="wait-img hide" />
                <div class="video-controls-container hide">
                  <div
                    class="timeline-container"
                    @mousedown="toggleScrubbing"
                    @mousemove="handleTimeline"
                  >
                    <div class="canvas" v-show="showGraphvar">
                      <canvas id="myChart" width="1200" height="65"></canvas>
                    </div>
                    <div class="timeline vjs-progress-holder">
                      <img class="preview-img" />
                      <div class="thumb-indicator"></div>
                    </div>
                    <div id="marker-timeline-container-1">
                      <div class="vjs-progress-holder"></div>
                    </div>
                    <div id="marker-timeline-container-2">
                      <div class="vjs-progress-holder"></div>
                    </div>
                    <div id="marker-timeline-container-3">
                      <div class="vjs-progress-holder"></div>
                    </div>
                    <div id="marker-timeline-container-4">
                      <div class="vjs-progress-holder"></div>
                    </div>
                    <div id="marker-timeline-container-5">
                      <div class="vjs-progress-holder"></div>
                    </div>
                  </div>
                  <div class="controls">
                    <div class="controls-left">
                      <button
                        class="play-pause-button vjs-play-control"
                        @click="togglePlay()"
                      >
                        <span class="play-icon vjs-icon-play"></span>
                        <span class="pause-icon vjs-icon-pause"></span>
                      </button>
                      <div class="duration-container">
                        <div class="current-time">{{ currentTime }}</div>
                        /
                        <div class="total-time">{{ totalDuration }}</div>
                      </div>
                    </div>
                    <div class="controls-center">
                      <button
                        v-if="!isRecording"
                        @click="startRecording"
                        style="width: 4rem"
                      >
                        Record
                      </button>
                      <button
                        v-if="isRecording"
                        id="stopButton"
                        @click="stopRecording"
                        style="width: 4rem"
                      >
                        Stop
                      </button>
                      <span v-if="isRecording">{{
                        formatTime(this.record_second)
                      }}</span>
                      <span
                        v-if="isRecording"
                        class="red-dot"
                        :class="{ blink: isRecording }"
                      ></span>
                    </div>
                    <div>
                      <button class="playback-button" @click="changePlayback">
                        {{ playbackRate }}x
                      </button>
                      <button class="zoomIn" @click="zoomIn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          class="bi bi-zoom-in"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                          />
                          <path
                            d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
                          />
                        </svg>
                      </button>
                      <button class="zoomOut" @click="zoomOut">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          class="bi bi-zoom-out"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                          />
                          <path
                            d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
                          />
                        </svg>
                      </button>
                      <button>
                        <v-icon size="20" @click="toggleFullscreen">{{
                          fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"
                        }}</v-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="panZoom-container">
                  <v-container class="overlay-video container-vp">
                    <v-progress-circular
                      style="margin-left: 410px; margin-top: 160px"
                      indeterminate
                      :size="150"
                      color="primary"
                    ></v-progress-circular>
                  </v-container>
                  <div class="media-abort" v-show="isMediaAbortError">
                    <v-dialog
                      v-model="isMediaAbortError"
                      :max-width="`450px`"
                      persistent
                    >
                      <v-card>
                        <v-card-title
                          >{{ this.mediaAbortErrorBody.title }}
                        </v-card-title>
                        <v-card-text>
                          {{ this.mediaAbortErrorBody.text }}
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="isMediaAbortError = false"
                            >{{ this.mediaAbortErrorBody.button }}</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="mask-source">
                    <img
                      class="video-mask-img"
                      v-if="videoLoadImgIndex === 0"
                      style="margin-left: 20%"
                      src="../../assets/video_change/BETSY_VideoLoading_0.png"
                    />
                    <img
                      class="video-mask-img"
                      v-if="videoLoadImgIndex === 1"
                      style="margin-left: 25%"
                      src="../../assets/video_change/BETSY_VideoLoading_1.png"
                    />
                    <img
                      class="video-mask-img"
                      v-if="videoLoadImgIndex === 2"
                      style="margin-left: 10%"
                      src="../../assets/video_change/BETSY_VideoLoading_2.png"
                    />
                  </div>
                  <div
                    class="heat-map-pic-container"
                    style="height: 100%; width: 100%; position: absolute"
                    v-show="toggleHeatMap"
                  ></div>
                  <img
                    src="../../assets/Onecup-Player.png"
                    style="display: none"
                    id="logo"
                  />
                  <img
                    src="../../assets/icon_cowcalving_colour.svg"
                    style="display: none"
                    id="calving_cow_image"
                  />
                  <img
                    src="../../assets/icon_cowchinresting_colour.svg"
                    style="display: none"
                    id="chinresting_cow_image"
                  />
                  <img
                    src="../../assets/icon_cowmounting_colour.svg"
                    style="display: none"
                    id="mounting_cow_image"
                  />
                  <img
                    src="../../assets/icon_cowsitting_colour.svg"
                    style="display: none"
                    id="laying_cow_image"
                  />
                  <img
                    src="../../assets/icon_cowstanding_colour.svg"
                    style="display: none"
                    id="standing_cow_image"
                  />
                  <img
                    src="../../assets/icon_cowheat_color.svg"
                    style="display: none"
                    id="heat_cow_image"
                  />
                  <video
                    id="videoPlayer"
                    ref="videoPlayer"
                    class="video-js"
                    data-setup="{ preload: auto }"
                    @timeupdate="timeUpdate"
                    style="display: none"
                  ></video>
                  <canvas
                    id="myCanvas"
                    width="952"
                    height="535.5"
                    ref="videoPlayerCanvas"
                    @click="handleCanvasClick"
                  >
                  </canvas>
                  <div
                    class="heat-map-container"
                    style="
                      width: 100%;
                      height: 100%;
                      background-color: aliceblue;
                      position: absolute;
                    "
                  ></div>
                  <canvas
                    id="invisibleCanvas"
                    :width="recorderCanvasWidth"
                    :height="recorderCanvasHeight"
                    ref="videoPlayerCanvas2"
                    style="display: none"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        :class="is_json ? 'ml-2' : ''"
        v-show="is_json && right_container && showSideContainers"
      >
        <div class="right-container" style="height: 535px">
          <v-card style="height: 65%" elevation="2">
            <v-tabs class="table_tabs_vp" grow height="25">
              <v-tab>Overlay Controls</v-tab>
            </v-tabs>
            <v-container fluid class="container-vp">
              <v-card-title class="justify-start inference-title">
                Annotations
              </v-card-title>
              <v-container fluid class="toggle-switches container-vp">
                <v-switch v-model="showbos" :disabled="boundingBoxDisabled">
                  <template #label>
                    <span>Bounding Box</span>
                  </template>
                </v-switch>
              </v-container>
              <v-container fluid class="toggle-switches container-vp">
                <v-switch v-model="bodyparts" :disabled="bodyPartsDisabled">
                  <template #label>
                    <span>Body Parts</span>
                  </template>
                </v-switch>
              </v-container>
              <v-container fluid class="toggle-switches container-vp">
                <v-switch v-model="keypoints" :disabled="keypointsDisabled">
                  <template #label>
                    <span>Key Points</span>
                  </template>
                </v-switch>
              </v-container>
            </v-container>

            <v-container fluid class="container-vp label-container">
              <v-card-title class="justify-start inference-title">
                Visuals
              </v-card-title>
              <v-container fluid class="toggle-switches container-vp">
                <v-switch v-model="bbox_fill" :disabled="boundingBoxDisabled">
                  <template #label>
                    <span>Color Fill</span>
                  </template>
                </v-switch>
              </v-container>
              <v-container fluid class="toggle-switches container-vp">
                <v-switch v-model="activityIcons">
                  <template #label>
                    <span>Icons</span>
                  </template>
                </v-switch>
              </v-container>
            </v-container>

            <v-container fluid class="container-vp label-container">
              <v-card-title class="justify-start inference-title">
                Labels
              </v-card-title>
              <v-container fluid class="toggle-switches container-vp">
                <v-switch v-model="labels" :disabled="!json_loaded">
                  <template #label>
                    <span>Anatomy Labels</span>
                  </template>
                </v-switch>
              </v-container>
            </v-container>

            <!-- <v-container fluid class="toggle-switches container-vp">
              <v-switch
                :label="`Bounding Box`"
                v-model="showbos"
                :disabled="boundingBoxDisabled"
              ></v-switch>
            </v-container>
            <v-container fluid class="toggle-switches-visual container-vp">
              <v-switch
                :label="`Color Fill`"
                :disabled="!json_loaded"
              ></v-switch>
            </v-container>
            <v-container fluid class="toggle-switches-visual container-vp">
              <v-switch
                :label="`Body Parts`"
                v-model="bodyparts"
                :disabled="bodyPartsDisabled"
              ></v-switch>
            </v-container>
            <v-container fluid class="toggle-switches-visual container-vp">
              <v-switch
                :label="`Key Points`"
                v-model="keypoints"
                :disabled="keypointsDisabled"
              ></v-switch>
            </v-container>
            <v-container fluid class="toggle-switches-visual container-vp">
              <v-switch :label="`Icons`" v-model="activityIcons"></v-switch>
            </v-container>
            <v-container fluid class="toggle-switches-visual container-vp">
              <v-switch
                :label="`Text`"
                v-model="labels"
                :disabled="!json_loaded"
              ></v-switch>
            </v-container> -->
          </v-card>

          <div style="width: 100%; margin-top: 1%">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <input
                type="text"
                v-model="search"
                placeholder="Search Tag"
                style="
                  border: 1px solid silver;
                  padding: 2px;
                  border-radius: 5px;
                  width: 40%;
                "
              />
              <v-btn x-small text @click="sortBy('tag_label')" elevation="2"
                >A-Z</v-btn
              >
              <v-btn x-small text @click="sortBy('tag_color')" elevation="2"
                >Color</v-btn
              >
            </div>
          </div>
          <div style="height: 30%">
            <v-card style="height: 100%; overflow-y: auto" elevation="2">
              <v-card-text>
                <div
                  v-if="filteredTags.length"
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                    max-height: 100%;
                    padding: 0;
                  "
                >
                  <v-chip
                    v-for="tag in filteredSearchTags"
                    :key="`${tag.tag_label}-${tag.tag_color}`"
                    @click="showDetailedTag(tag.tag_label)"
                    small
                    :outlined="tag.isSelected"
                    :color="
                      tag.isSelected
                        ? '#0090a4'
                        : tag.tag_color
                        ? `oc_${tag.tag_color.toLowerCase()} darken-1`
                        : 'grey darken-1'
                    "
                    :style="{
                      color: tag.isSelected ? '#0090A4' : 'white',
                      fontWeight: '500',
                      padding: '0 12px',
                      borderRadius: '20px',
                    }"
                  >
                    <span>{{ tag.tag_label }}</span></v-chip
                  >
                </div>
                <div v-else>
                  <div style="margin-top: 30px">
                    <div
                      style="
                        font-size: 14px;
                        letter-spacing: 2px;
                        color: #0090a4;
                        text-align: center;
                      "
                    >
                      TAGS
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        letter-spacing: 2px;
                        color: #0090a4;
                        text-align: center;
                      "
                    >
                      IDENTIFICATION
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        letter-spacing: 2px;
                        color: #0090a4;
                        text-align: center;
                      "
                    >
                      PENDING
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="frame-buttons-container">
            <v-btn elevation="2" small @click="prevFrame">Prev</v-btn>
            <v-btn elevation="2" small @click="nextFrame">Next</v-btn>
            <v-btn elevation="2" small @click="cancelFrame">Cancel</v-btn>
          </div>
        </div></v-col
      >
    </v-row>
    <div style="display: flex; justify-content: space-between">
      <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
        >Version No: 3.0.0</span
      >
      <span v-show="!isAdminPortal">
        <twitter-button class="share-button--outline" btnText />
        <facebook-button class="share-button--outline" btnText />
        <linkedIn-button class="share-button--outline" btnText />
        <telegram-button class="share-button--outline" btnText />
      </span>

      <span
        v-show="isAdminPortal"
        x
        style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
        >VideoFps (DB-{{ this.frame_rate_database }}) (CAL-{{
          parseFloat(this.frame_rate.toFixed(5))
        }}) (JSON-{{ this.videoFPS_JSON }})</span
      >
      <span
        v-show="isAdminPortal"
        style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
        >Video (W-{{ this.videoWidth }}) (H-{{ this.videoHeight }})</span
      >
      <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
        >Last updated: August 28 '24</span
      >
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import placeholder_img from "@/assets/cover_brown.png";
import placeholder_body_img from "@/assets/cover_brown_body.png";
import videojs from "video.js";
import Panzoom from "@panzoom/panzoom";
import { eventBus } from "../../main.js";
import Chart from "chart.js";
import "videojs-overlay";
import "../video_player/videojs_markers/videojs-markers.js";
import "../video_player/videojs_markers/videojs.markers.min.css";
import TwitterButton from "../video_player/vuesharebuttons/components/TwitterButton";
import FacebookButton from "../video_player/vuesharebuttons/components/FacebookButton";
import LinkedInButton from "../video_player/vuesharebuttons/components/LinkedInButton";
import TelegramButton from "../video_player/vuesharebuttons/components/TelegramButton";
import parseJSON from "fast-json-parse";
import UnzipWorker from "worker-loader!./unzipWorker";
import GraphWorker from "worker-loader!./graphWorker";
import heatMapWorker from "worker-loader!./heatMapWorker";
import eventFinderWorker from "worker-loader!./eventFinderWorker";
import bboxMergerWorker from "worker-loader!./bboxMergerWorker";
import bodypartsMergerWorker from "worker-loader!./bodypartsMergerWorker";
import keypointsMergerWorker from "worker-loader!./keypointsMergerWorker";
import svgImage from "../../assets/dog_standing_icon_v2.svg";
import svgImageScratching from "../../assets/dog_scratching_icon_v2.svg";
import h337 from "heatmap.js";

export default {
  name: "VideoPlayer",
  components: {
    TwitterButton,
    FacebookButton,
    LinkedInButton,
    TelegramButton,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    json_data: String,
    is_json: Boolean,
    timeStamp: String,
    json_file_not_present: Boolean,
    navigation_button_change: Boolean,
    videoFps: Number,
    camera_model: String,
    portal: String,
    customerFirstName: String,
    customerLastName: String,
    json_loading: Boolean,
    videoPlayerDialog: Boolean,
    json_url: String,
    customer_id: String,
  },
  data() {
    return {
      showSideContainers: window.innerWidth >= 1450,
      filteredTags: [],
      placeholder_img: placeholder_img,
      placeholder_body_img: placeholder_body_img,
      animals: [],
      tabs: null,
      bbox_fill: false,
      isbbox_fill: false,
      j_data_string: "",
      cx_logo_image: new Image(),
      keypointWorker: null,
      bodypartsWorker: null,
      bboxWorker: null,
      bboxProcessedObjectKeys: [],
      lastBBoxFrameFetched: 0,
      bodyPartsProcessedObjectKeys: [],
      lastBodyPartsFrameFetched: 0,
      isFetchingNextChunkBBox: false,
      isFetchingNextChunkBodyParts: false,
      firstChunkReceived: {
        bbox: false,
        bodyparts: false,
        keypoints: false,
      },
      allFirstChunksLoaded: false,
      keypointsDisabled: true,
      boundingBoxDisabled: false,
      bodyPartsDisabled: true,
      preprocessedBBOX: {},
      preprocessedBodyparts: {},
      preprocessedKeypoints: {},
      svgImage: svgImage,
      nextContainerClassIndex: 0,
      recieve_json: false,
      toggleHeatMap: false,
      heatMapWorkerFail: true,
      standingHeat_timestamps: [],
      standingHeatEventPresent: false,
      standingHeatEvents: false,
      mounting_timestamps: [],
      mountingEventPresent: false,
      mountingEvents: false,
      chinResting_timestamps: [],
      gender_timestamps: [],
      grazing_timestamps: [],
      chinRestingEventPresent: false,
      chinRestingEvents: false,
      isActivityPresent: false,
      isActivity: false,
      isGrazingPresent: false,
      isGrazing: false,
      isGenderPresent: false,
      isGender: false,
      isAgePresent: false,
      isAge: false,
      jsonType: "",
      vehicleClass: "vehicle",
      keypointType: -1,
      new_tab: "visualizations",
      svgImageScratching: svgImageScratching,
      json_fetch_url: "",
      tab: "overview",
      tab_elanco: "models",
      portalRunning: this.portal,
      player: null,
      total_frames_of_video: 0,
      frame_rate: 0,
      frame_rate_database: this.videoFps,
      json_loaded: true,
      json_file_nt_present: this.json_file_not_present,
      videoFPS_JSON: "N/A",
      videoWidth: "N/A",
      videoHeight: "N/A",
      frame_interval: 30,
      isMediaAbortError: false,
      bodyparts: false,
      keypoints: false,
      showbos: true,
      activityIcons: false,
      animalID: false,
      labels: false,
      predatorEvents: false,
      calvingEvents: false,
      isPredatorEventsPresent: false,
      isCalvingEventsPresent: false,
      isBodyparts: false,
      isKeypoints: false,
      isShowbos: false,
      isActivityIcons: false,
      isAnimalID: false,
      isLabels: false,
      initialVideoValue: 1,
      customPlay: false,
      my_time: null,
      x: 952,
      y: 535.5,
      recorderCanvasWidth: 2560,
      recorderCanvasHeight: 1440,
      j_data: {},
      j_string: "",
      isPlaying: false,
      playbackRate: 1,
      currentTime: "0:00",
      totalDuration: "0:00",
      totalDurationInt: 0,
      isScrubbing: false,
      isPaused: false,
      frameArray: [],
      videoTimeStamp: "",
      thumbnailArray: {},
      source: "",
      xValues: [],
      yValues: [],
      playedOnce: false,
      search: "",
      items: ["A-Z", "Color"],
      sortByColor: false,
      showGraphvar: false,
      graphChart: {},
      displayCurrentAnimal: [],
      displayingOneAnimal: false,
      front_image_select: true,
      listAllAnimals: [...this.$store.getters.getAnimals],
      left_container: false,
      right_container: false,
      betsy_switch: false,
      classificationDisabled: true,
      logo_items: [
        { title: "No-Logo" },
        { title: "Top-Left" },
        { title: "Top-Right" },
        { title: "Bottom-Left" },
        { title: "Bottom-Right" },
      ],
      animationID: null,
      cx_logo_loaded: false,
      customer_logo_orientation: "No-Logo",
      recorder: {},
      recordedBlob: {},
      record_second: 0,
      intervalID: 0,
      isRecording: false,
      recording: false,
      mediaRecorder: null,
      cameraModel: "",
      customer_first_name: this.customerFirstName,
      customer_last_name: this.customerLastName,
      chunks: [],
      listOfCamera: ["E1 Outdoor"],
      videoLoadImgIndex: Math.floor(Math.random() * 3),
      trackedObjects: {},
      animalVideoLabels: [],
      reverseTrackedObjects: new Map(),
      animalFrameOccurrence: new Map(),
      fullscreen: false,
      selectedTag: -1,
      predatorEventPresent: false,
      calvingEventPresent: false,
      predator_timestamps: [],
      predator_markers: [],
      calving_timestamps: [],
      calving_markers: [],
      calving_and_predators_timestamps: [],
      calving_and_predators_markers: [],
      activity_timestamps: [],
      showTooltip: false,
      message: "This is the information message.",
      dialog: false,
      confidenceCutoff: false,
      confidenceCutoffNumber: 0,
      confidenceCutoffInputValue: 0,
      mediaAbortErrorBody: {
        title: "Can Not Resume Video Playback",
        text: "Sorry, we cannot continue playing this video. There is a problem with the playback. The video may be corrupted.",
        button: "Close",
      },
    };
  },
  watch: {
    // reset command
    source() {
      this.filteredTags = [];
      this.j_data_string = "";
      this.activeWorkers = {};
      (this.isDataAvailable = false),
        (this.keypointsDisabled = true),
        (this.boundingBoxDisabled = false),
        (this.bodyPartsDisabled = true),
        (this.keypoints = false),
        (this.bodyparts = false),
        (this.preprocessedBBOX = {}),
        (this.preprocessedBodyparts = {}),
        (this.preprocessedKeypoints = {}),
        this.removeHeatmap();
      this.toggleHeatMap = false;
      this.standingHeatEvents = false;
      this.calvingEvents = false;
      this.chinRestingEvents = false;
      this.mountingEvents = false;
      this.predatorEvents = false;
      this.removeHelper(false);
      this.player.playbackRate(1);
      this.playbackRate = 1;
      this.isPlaying = false;
      this.j_data = {};
      this.jsonType = "";
      this.keypointType = -1;
      this.heatMapWorkerFail = true;
      this.calvingEventPresent = false;
      this.predatorEventPresent = false;
      this.recieve_json = false;
      this.frame_rate = 30;
      this.frame_interval = 30;
      this.selectedTag = -1;
      document.querySelector(".video-container").classList.add("paused");
      this.player.pause();
      const loaderDiv = document.querySelector(".overlay-video");
      loaderDiv.classList.remove("hide");
      this.showGraphvar = false;
      this.clearList(this.xValues);
      this.clearList(this.yValues);
      this.updateGraphChart();
      this.predator_timestamps = [];
      this.calving_timestamps = [];
      this.activity_timestamps = [];
      this.player.markers.removeAll();
      const maskSourceChange = document.querySelector(".mask-source");
      maskSourceChange.classList.remove("hide");
      this.isMediaAbortError = false;
      this.$emit("update-loading", false);
      this.json_loaded = false;
      this.fetchJSON();
    },
    videoWidth() {
      this.recorderCanvasWidth = this.videoWidth;
    },
    videoHeight() {
      this.recorderCanvasHeight = this.videoHeight;
    },
    displayingOneAnimal() {
      this.todisplayingOneAnimal = this.displayingOneAnimal;
    },
    json_url() {
      this.json_fetch_url = this.json_url;
    },
    timeStamp() {
      this.videoTimeStamp = this.timeStamp;
    },
    selectedTag() {
      this.removeHeatmap();
      this.generateHeatmap();
    },
    recieve_json() {
      if (this.recieve_json) {
        this.showGraph(this.j_data);
        this.generateHeatmap();
        // this.j_data = {};
      }
    },
    videoPlayerDialog() {
      var player = document.querySelector("#videoPlayer");
      if (!this.videoPlayerDialog) {
        player.player.pause();
        if (this.keypointWorker) {
          this.keypointWorker.terminate();
          this.keypointWorker = null;
        }
        if (this.bboxWorker) {
          this.bboxWorker.terminate();
          this.bboxWorker = null;
        }

        if (this.bodypartsWorker) {
          this.bodypartsWorker.terminate();
          this.bodypartsWorker = null;
        }
      }
    },
    is_json() {
      this.betsy_switch = this.is_json;
      if (this.is_json) {
        setTimeout(() => {
          this.left_container = true;
          this.right_container = true;
        }, 300);
      } else {
        this.left_container = false;
        this.right_container = false;
        // setTimeout(() => {
        //   this.left_container = false;
        //   this.right_container = false;
        // }, 300);
      }
    },
    json_loading() {
      this.json_loaded = this.json_loading;
    },
    json_file_not_present() {
      this.json_file_nt_present = this.json_file_not_present;
      if (this.json_file_nt_present) {
        this.videoFPS_JSON = "N/A";
        this.videoWidth = "N/A";
        this.videoHeight = "N/A";
      }
    },
    videoFps() {
      this.frame_rate_database = this.videoFps;
    },
    camera_model() {
      this.cameraModel = this.camera_model;
    },
    customerFirstName() {
      this.customer_first_name = this.customerFirstName;
    },
    customerLastName() {
      this.customer_last_name = this.customerLastName;
    },
    portal() {
      this.portalRunning = this.portal;
    },
    navigation_button_change() {
      if (this.keypointWorker) {
        this.keypointWorker.terminate();
        this.keypointWorker = null;
      }
      if (this.bboxWorker) {
        this.bboxWorker.terminate();
        this.bboxWorker = null;
      }

      if (this.bodypartsWorker) {
        this.bodypartsWorker.terminate();
        this.bodypartsWorker = null;
      }
      this.videoLoadImgIndex = Math.floor(Math.random() * 3);
      this.stopRecording();
    },
    predatorEvents() {
      if (this.predatorEvents) {
        this.drawMarkers("predator");
      } else {
        this.removeHelper(true);
      }
    },
    calvingEvents() {
      if (this.calvingEvents) {
        this.drawMarkers("calving");
      } else {
        this.removeHelper(true);
      }
    },
    mountingEvents() {
      if (this.mountingEvents) {
        this.drawMarkers("mounting");
      } else {
        this.removeHelper(true);
      }
    },
    chinRestingEvents() {
      if (this.chinRestingEvents) {
        this.drawMarkers("resting_chin");
      } else {
        this.removeHelper(true);
      }
    },
    standingHeatEvents() {
      if (this.standingHeatEvents) {
        this.drawMarkers("heat");
      } else {
        this.removeHelper(true);
      }
    },
  },
  methods: {
    convertToTitleCase(str) {
      let result = "";
      let capitalizeNext = true;

      for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (char === "_") {
          capitalizeNext = true;
          result += " ";
        } else {
          result += capitalizeNext ? char.toUpperCase() : char;
          capitalizeNext = false;
        }
      }

      return result;
    },
    toggle_animal_image() {
      this.front_image_select = !this.front_image_select;
    },
    handleImageError(currentAnimal) {
      let foundAnimalIndex = this.filteredTags.indexOf(currentAnimal);
      this.filteredTags[foundAnimalIndex].imageUrl = this.placeholder_img;
      this.filteredTags[foundAnimalIndex].imageUrlBody =
        this.placeholder_body_img;
    },
    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1450;
      if (this.showSideContainers) {
        setTimeout(() => {
          this.left_container = true;
          this.right_container = true;
        }, 600);
      } else {
        this.left_container = false;
        this.right_container = false;
      }
    },
    removeAllMarkersAndContainers() {
      for (let i = 1; i <= 4; i++) {
        let markerContainer = document.getElementById(
          `marker-timeline-container-${i}`
        );
        while (markerContainer.firstChild) {
          markerContainer.removeChild(markerContainer.firstChild);
        }
        // Clear all classes and reset display
        markerContainer.className = "";
        markerContainer.style.display = "none";
      }
    },
    reDrawMarkers() {
      if (this.predatorEvents) {
        this.drawMarkers("predator");
      }
      if (this.calvingEvents) {
        this.drawMarkers("calving");
      }

      if (this.mountingEvents) {
        this.drawMarkers("mounting");
      }

      if (this.chinRestingEvents) {
        this.drawMarkers("resting_chin");
      }
      if (this.standingHeatEvents) {
        this.drawMarkers("heat");
      }

      if (
        !this.calvingEvents &&
        !this.predatorEvents &&
        !this.standingHeatEvents &&
        !this.mountingEvents &&
        !this.chinRestingEvents
      ) {
        this.showGraphvar = true;
      }
    },
    removeHelper(shouldRedraw) {
      this.removeAllMarkersAndContainers();
      if (shouldRedraw) {
        this.reDrawMarkers();
      }
      // @Todo: Predator markers
    },
    removeHeatmap() {
      // Get the reference to the div container
      var divContainer = document.querySelector(".heat-map-container");
      var heatmapImgContainer = document.querySelector(
        ".heat-map-pic-container"
      );
      // Check if the div container has any children
      if (divContainer.hasChildNodes()) {
        // Remove all children from the div container
        while (divContainer.firstChild) {
          divContainer.removeChild(divContainer.firstChild);
        }
      }
      if (heatmapImgContainer.hasChildNodes()) {
        // Remove all children from the div container
        while (heatmapImgContainer.firstChild) {
          heatmapImgContainer.removeChild(heatmapImgContainer.firstChild);
        }
      }
    },
    async generateHeatmap() {
      const heatmapWorker = new heatMapWorker();
      const dataFrame = this.j_data;
      heatmapWorker.addEventListener("message", (event) => {
        const { success, heatmapData, error } = event.data;
        if (success) {
          const dataPoints = heatmapData.heatMapPoints;
          const max = heatmapData.max;
          // // change any default settings when declaring heatmapInstance
          var heatmapInstance = h337.create({
            container: document.querySelector(".heat-map-container"),
            minOpacity: 0.05,
            maxOpacity: 0.7,
            // blur: 0.2,
            // radius: 90
          });
          var data = {
            max: max,
            data: dataPoints,
          };
          heatmapInstance.setData(data);
          this.heatMapWorkerFail = false;
          var heatmapCanvas = document.querySelector(".heatmap-canvas");
          var heatmapImgContainer = document.querySelector(
            ".heat-map-pic-container"
          );
          const imageElement = document.createElement("img");
          imageElement.src = heatmapCanvas.toDataURL("image/png");
          heatmapImgContainer.appendChild(imageElement);
          //
        } else {
          console.error("Error generating Heatmap: ", error);
        }
        // Terminate the web worker
        heatmapWorker.terminate();
      });
      const sendMessage = {
        frameArray: dataFrame,
        x: this.x,
        y: this.y,
        videoWidth: this.videoWidth,
        videoHeight: this.videoHeight,
        jsonType: this.jsonType,
        selectedTag: this.selectedTag,
      };
      heatmapWorker.postMessage({ sendMessage });
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    mountGraph() {
      const chart = document.getElementById("myChart");
      this.graphChart = new Chart(chart, {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              backgroundColor: "rgba(192,192,192,1.0)",
              borderColor: "rgba(0,0,255,0.1)",
              cubicInterpolationMode: "bezier",
              data: [],
            },
          ],
        },
        options: {
          elements: {
            line: {
              tension: 0.6,
            },
            point: {
              radius: 0,
            },
          },
          legend: { display: false },
          scales: {
            yAxes: [
              {
                ticks: {
                  display: false,
                  beginAtZero: true,
                  max: 5,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    },
    updateGraphChart() {
      this.$nextTick(() => {
        this.fillGraphChartValues(this.j_data["frame"]);
      });
    },
    fillGraphChartValues(frameArray) {
      const graphWorker = new GraphWorker();
      graphWorker.addEventListener("message", (event) => {
        // do something with data
        const { success, graphData, error } = event.data;
        if (success) {
          Object.assign(this.xValues, graphData.xValues);
          Object.assign(this.yValues, graphData.yValues);
          this.graphChart.data.labels = this.xValues;
          this.graphChart.data.datasets[0].data = this.yValues;
          this.graphChart.update();
          // this.generateGraph(this.xValues, this.yValues);
        } else {
          console.log(`Error generating graph: ${error}`);
        }
        // terminate worker
        graphWorker.terminate();
      });
      // Start graph generation in the web worker
      const sendMessage = {
        frameArray: frameArray,
        frame_interval: this.frame_interval,
        bboxCutoffValue: this.bboxCutoffValue,
        jsonType: this.jsonType,
      };
      graphWorker.postMessage({ sendMessage });
    },
    handleBboxCutoffRange() {
      this.clearList(this.xValues);
      this.clearList(this.yValues);
      this.updateGraphChart();
    },

    checkConf(conf) {
      // Remove the '%' sign and convert the value to a number
      const confidence = parseFloat(conf);

      // Check if the confidence is within bboxCutoffValue range
      if (
        confidence >= this.bboxCutoffValue[0] &&
        confidence <= this.bboxCutoffValue[1]
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleFrameInterval() {
      this.frame_interval = 30;
    },
    async fetchJSON() {
      const url = this.json_url;
      this.json_loaded = false;
      let json_file_source;

      json_file_source = process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

      try {
        const response = await fetch(json_file_source, {
          credentials: "include",
        });
        if (!response.ok) {
          return;
        } else {
          const zipData = await response.arrayBuffer();
          // Create a web worker
          const unzipWorker = new UnzipWorker();
          // Listen for messages from the web worker
          unzipWorker.addEventListener("message", (event) => {
            const { success, jsonData, error } = event.data;
            if (success) {
              this.j_data_string = jsonData;
              this.startAnnotations();
              this.bodyPartsAnnotations();
              this.keypointsAnnotations();
              const rawData = parseJSON(jsonData);
              if (rawData.err == null) {
                Object.assign(this.j_data, rawData.value);
                this.recieve_json = true;
                this.$emit("update-loading", true);
                this.json_loaded = true;
                const json_type = this.j_data.json_type;
                if (json_type) {
                  this.jsonType = this.j_data.json_type;
                } else {
                  this.jsonType = "default";
                }
                const keypoint_type = this.j_data.keypoint_type;
                if (keypoint_type) {
                  this.keypointType = keypoint_type;
                }
                this.handleFrameInterval();
                let frameCount = this.j_data["info"]["frame_count"];
                if (frameCount) {
                  this.total_frames_of_video =
                    this.j_data["info"]["frame_count"];
                } else {
                  // miss data
                  const allKeys = Object.keys(this.j_data["frame"]);
                  this.total_frames_of_video =
                    allKeys.length * this.frame_interval;
                }

                this.videoFPS_JSON = this.j_data["info"]["fps"];
                this.videoWidth = this.j_data["info"]["width"];
                this.videoHeight = this.j_data["info"]["height"];
                this.findEvents();

                this.extractTrackedObjects(this.j_data);
                this.tagOccurenceInFrames(this.j_data);
                this.reverseExtractTrackedObjects(this.trackedObjects);
                this.j_data_string = "";
              }
            } else {
              console.error("Error unzipping JSON: ", error);
            }
            // Terminate the web worker
            unzipWorker.terminate();
          });
          // Start the unzipping process in the web worker
          unzipWorker.postMessage({ zipData });
        }
      } catch {
        console.log("error with unzip worker");
      }
    },
    addSeconds(startTime, seconds) {
      // Split the start time into hours, minutes, and seconds
      var [startHours, startMinutes, startSeconds] = startTime.split(":");
      // Convert the time values to integers
      startHours = parseInt(startHours, 10);
      startMinutes = parseInt(startMinutes, 10);
      startSeconds = parseInt(startSeconds, 10);
      // Convert the duration to an integer
      seconds = parseInt(seconds, 10);
      // Calculate the updated time
      var updatedSeconds = startSeconds + seconds;
      var updatedMinutes = startMinutes + Math.floor(updatedSeconds / 60);
      var updatedHours = startHours + Math.floor(updatedMinutes / 60);
      // Adjust the minutes and seconds if they exceed their respective ranges
      updatedSeconds %= 60;
      updatedMinutes %= 60;
      updatedHours %= 24; // Wrap around to the next day if necessary
      // Format the updated time as HH:mm:ss
      var updatedTime = `${updatedHours
        .toString()
        .padStart(2, "0")}:${updatedMinutes
        .toString()
        .padStart(2, "0")}:${updatedSeconds.toString().padStart(2, "0")}`;
      return updatedTime;
    },
    findEvents() {
      this.findClassification(this.j_data);
    },
    drawMarkers(type) {
      this.showGraphvar = false;

      // A variable to keep track of the next container class index.
      let nextContainerClassIndex = 1;

      // Check existing marker containers to determine the next class index.
      for (let i = 1; i <= 4; i++) {
        if (
          document
            .getElementById(`marker-timeline-container-${i}`)
            .classList.contains(`marker-container-${i}`)
        ) {
          nextContainerClassIndex++;
        }
      }

      // Get the marker container and clear any existing classes.
      let markerContainer = document.getElementById(
        `marker-timeline-container-${nextContainerClassIndex}`
      );
      markerContainer.className = "";

      // Determine the timestamps and color based on the type.
      let timestamps;
      let color;
      switch (type) {
        case "calving":
          timestamps = this.calving_timestamps;
          color = "rgb(60, 102, 104)";
          break;
        case "resting_chin":
          timestamps = this.chinResting_timestamps;
          color = "rgb(116, 165, 169)";
          break;
        case "mounting":
          timestamps = this.mounting_timestamps;
          color = "rgb(141, 82, 50)";
          break;
        case "heat":
          timestamps = this.standingHeat_timestamps;
          color = "#a0215f";
          break;
        // @Todo: Predator markers
      }

      // If there are timestamps, create and append the markers.
      if (timestamps && timestamps.length !== 0) {
        for (let i = 0; i < timestamps.length; i++) {
          let markerPosition = timestamps[i] * 100;
          let marker = document.createElement("div");
          marker.style.position = "absolute";
          marker.style.zIndex = 2;
          marker.style.width = "0.7px";
          marker.style.height = "10px";
          marker.style.borderRadius = "10%";
          marker.style.backgroundColor = color;
          marker.style.left = `${markerPosition}%`;
          markerContainer.appendChild(marker);
        }
      }

      // Display the marker container and add the class.
      markerContainer.style.display = "block";
      markerContainer.classList.add(
        `marker-container-${nextContainerClassIndex}`
      );
    },
    findClassification(j_data) {
      this.standingHeat_timestamps = [];
      this.standingHeatEventPresent = false;
      this.calving_timestamps = [];
      this.calvingEventPresent = false;
      this.chinResting_timestamps = [];
      this.chinRestingEventPresent = false;
      this.mounting_timestamps = [];
      this.mountingEventPresent = false;
      this.predator_timestamps = [];
      this.predatorEventPresent = false;
      this.isActivityPresent = false;
      this.isGrazingPresent = false;
      this.isGenderPresent = false;

      const eventsFinder = new eventFinderWorker();
      eventsFinder.addEventListener("message", (event) => {
        const { success, eventsData, error } = event.data;
        if (success) {
          Object.assign(
            this.standingHeat_timestamps,
            eventsData.standingHeat_timestamps
          );
          Object.assign(this.calving_timestamps, eventsData.calving_timestamps);
          Object.assign(
            this.chinResting_timestamps,
            eventsData.chinResting_timestamps
          );
          Object.assign(
            this.mounting_timestamps,
            eventsData.mounting_timestamps
          );
          Object.assign(
            this.predator_timestamps,
            eventsData.predator_timestamps
          );
          Object.assign(
            this.activity_timestamps,
            eventsData.activity_timestamps
          );
          Object.assign(this.grazing_timestamps, eventsData.grazing_timestamps);
          Object.assign(this.gender_timestamps, eventsData.gender_timestamps);
          if (this.standingHeat_timestamps.length) {
            this.standingHeatEventPresent = true;
          }
          if (this.calving_timestamps.length) {
            this.calvingEventPresent = true;
          }
          if (this.chinResting_timestamps.length) {
            this.chinRestingEventPresent = true;
          }
          if (this.mounting_timestamps.length) {
            this.mountingEventPresent = true;
          }
          if (this.predator_timestamps.length) {
            this.predatorEventPresent = true;
          }
          if (this.activity_timestamps.length) {
            this.isActivityPresent = true;
          }
          if (this.grazing_timestamps.length) {
            this.isGrazingPresent = true;
          }
          if (this.gender_timestamps.length) {
            this.isGenderPresent = true;
          }
        } else {
          console.error("Error Finding Events: ", error);
        }
        // Terminate the web worker
        eventsFinder.terminate();
      });
      const sendMessage = {
        j_data: j_data,
        jsonType: this.jsonType,
        totalFrames: this.total_frames_of_video,
      };
      eventsFinder.postMessage({ sendMessage });
    },

    async showDetailedTag(label) {
      this.resetTags();
      const tagIndex = this.filteredTags.findIndex(
        (tag) => tag.tag_label === label
      );
      if (tagIndex !== -1) {
        this.filteredTags[tagIndex].isSelected = true;
        // display only one animal
        this.displayingOneAnimal = true;
        this.displayCurrentAnimal = this.filteredTags[tagIndex];
        this.selectTag(this.filteredTags[tagIndex].tracking_id, "tag");
      }

      //  let trackingID = this.trackedObjects[label]["tracking_id"];
      // console.log(label);
      // console.log(this.trackedObjects);
      // this.selectTag(trackingID);thi
    },
    async selectTag(trackingID, event = "canvas") {
      if (event == "canvas") {
        this.filteredTags.forEach((tag) => {
          tag.isSelected = false;
        });

        const tagIndex = this.filteredTags.findIndex(
          (tag) => tag.tracking_id === trackingID
        );
        if (tagIndex !== -1) {
          this.filteredTags[tagIndex].isSelected = true;
          this.displayingOneAnimal = true;
          this.displayCurrentAnimal = this.filteredTags[tagIndex];
        }
      }

      this.player.markers.removeAll();
      this.frameArray = this.animalFrameOccurrence.get(parseInt(trackingID));
      for (let i = 0; i < this.frameArray.length; i++) {
        this.player.markers.add([
          {
            // time: this.frameArray[i] / this.frame_interval,
            time:
              (this.totalDurationInt / this.total_frames_of_video) *
              this.frameArray[i],
          },
        ]);
      }
      this.selectedTag = trackingID;
    },
    handleCanvasClick(event) {
      const canvas = this.$refs.videoPlayerCanvas;
      var video = this.$refs.videoPlayer;
      var currentFrame = Math.floor(video.currentTime * this.frame_rate);
      const scaleX = (value) => this.scaleX(value);
      const scaleY = (value) => this.scaleY(value);
      const rect = canvas.getBoundingClientRect();
      const x = event.offsetX || event.clientX - rect.left;
      const y = event.offsetY || event.clientY - rect.top;

      let currentFrameBBOXs = this.preprocessedBBOX[currentFrame] || [];

      for (const obj of currentFrameBBOXs) {
        if (
          x >= scaleX(obj.bbox.x1) &&
          x <= scaleX(obj.bbox.x1) + scaleX(obj.bbox.w) &&
          y >= scaleY(obj.bbox.y1) &&
          y <= scaleY(obj.bbox.y1) + scaleY(obj.bbox.h)
        ) {
          this.selectTag(obj.tracking_id);
          break;
        }
      }
    },

    clearHashMap(map) {
      if (map.size > 0) {
        map.clear();
      }
    },
    async tagOccurenceInFrames(j_data) {
      this.clearHashMap(this.animalFrameOccurrence);
      let json;
      for (var key in j_data["frame"]) {
        if (this.jsonType == "default") {
          json = j_data["frame"][key];
        } else {
          json = j_data["frame"][key].objects;
        }

        if (json) {
          for (let i = 0; i < json.length; i++) {
            let obj = json[i];
            let trackingId = obj["tracking_id"];
            if (this.animalFrameOccurrence.get(trackingId)) {
              let existing_arr = this.animalFrameOccurrence.get(trackingId);
              this.animalFrameOccurrence.set(trackingId, [
                ...existing_arr,
                key,
              ]);
            } else {
              this.animalFrameOccurrence.set(trackingId, [key]);
            }
          }
        }
      }
    },
    async extractTrackedObjects(jsonData) {
      // Clear existing trackedObjects
      for (let key in this.trackedObjects) {
        if (this.trackedObjects[key]) {
          delete this.trackedObjects[key];
        }
      }

      // Iterate through each frame
      for (const frameKey in jsonData["frame"]) {
        const frameData = jsonData["frame"][frameKey];

        // Check if the frame has objects
        if (frameData && frameData["objects"]) {
          // Iterate through objects in the frame
          for (const object of frameData["objects"]) {
            // Check if object has an id and conf fields
            if (object["id"] && object["id"]["conf"]) {
              let label = object["id"]["label"];
              let conf = object["id"]["conf"];
              // Store or update the object information
              this.trackedObjects[label] = {
                conf: conf,
                tracking_id: object["tracking_id"]
                  ? object["tracking_id"]
                  : null,
                frame: frameKey,
                selected: false,
              };
            }
          }
        }
      }

      // Update animalVideoLabels
      this.animalVideoLabels = Object.entries(this.trackedObjects);
      this.modifyAnimalVideoLabels(this.animalVideoLabels);
    },
    reverseExtractTrackedObjects(trackedObjects) {
      this.clearHashMap(this.reverseTrackedObjects);
      for (let key in trackedObjects) {
        let trackedID = trackedObjects[key].tracking_id;
        let trackedIDConf = trackedObjects[key].conf;
        this.reverseTrackedObjects.set(trackedID, {
          conf: trackedIDConf,
          ocr_read: key,
        });
      }
    },
    modifyAnimalVideoLabels(animalVideoLabels) {
      for (let element of animalVideoLabels) {
        if (element[1].tracking_id == this.selectedTag) {
          element[1].selected = true;
        }
      }
    },
    randomZeroOneTwo() {
      return Math.floor(Math.random() * 3);
    },
    clearList(list) {
      while (list.length != 0) {
        list.pop();
      }
    },
    toggleFullscreen() {
      const canvas = this.$refs.videoPlayerCanvas;
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        canvas.requestFullscreen();
      }
    },

    clearObject(obj) {
      for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          delete obj[prop];
        }
      }
    },

    processAnnotations(workerTypeString, destinationObject) {
      if (this.is_json) {
        let WorkerType;
        switch (workerTypeString) {
          case "bboxMergerWorker":
            WorkerType = bboxMergerWorker;
            break;
          case "bodypartsMergerWorker":
            WorkerType = bodypartsMergerWorker;
            break;
          case "keypointsMergerWorker":
            WorkerType = keypointsMergerWorker;
            break;
        }

        const worker = new WorkerType();

        switch (workerTypeString) {
          case "bboxMergerWorker":
            this.bboxWorker = worker;
            break;
          case "bodypartsMergerWorker":
            this.bodypartsWorker = worker;
            break;
          case "keypointsMergerWorker":
            this.keypointWorker = worker;
            break;
        }

        worker.addEventListener("message", (event) => {
          const { success, sendData, error } = event.data;

          if (sendData && sendData.data) {
            this.clearObject(destinationObject);
            const jsonDataProcessed = sendData.data;
            if (success) {
              const jsonDataString = new TextDecoder().decode(
                new Uint8Array(jsonDataProcessed)
              );
              const dataChunk = parseJSON(jsonDataString);

              Object.assign(destinationObject, dataChunk.value);

              switch (workerTypeString) {
                case "bboxMergerWorker":
                  if (this.boundingBoxDisabled) {
                    this.boundingBoxDisabled = false;
                    this.showbos = true;
                    this.labels = true;
                  }
                  this.isFetchingNextChunkBBox = false;
                  this.bboxProcessedObjectKeys = Object.keys(destinationObject);

                  break;
                case "bodypartsMergerWorker":
                  if (this.bodyPartsDisabled) {
                    this.bodyPartsDisabled = false;

                    //this.bodyparts = true;
                  }
                  (this.isFetchingNextChunkBodyParts = false),
                    (this.bodyPartsProcessedObjectKeys =
                      Object.keys(destinationObject));

                  if (sendData.storedTags.length > 0) {
                    this.checkForApprovedTags(sendData.storedTags);
                    //this.filteredTags = sendData.storedTags;
                  }
                  // console.log(sendData.storedTags);
                  break;
                case "keypointsMergerWorker":
                  if (this.keypointsDisabled) {
                    this.keypointsDisabled = false;
                  }

                  break;
              }
            } else {
              console.log(`Error merging data: ${error}`);
            }
          }

          // if (sendData && sendData.fulfilled) {
          //   // terminate worker
          //   console.log("termination worker ");
          //   worker.terminate();
          //   switch (workerTypeString) {
          //     case "bboxMergerWorker":
          //       this.bboxWorker = null;
          //       break;
          //     case "bodypartsMergerWorker":
          //       this.bodypartsWorker = null;
          //       break;
          //     case "keypointsMergerWorker":
          //       this.keypointWorker = null;
          //       break;
          //   }
          // }
        });
        const sendMessage = {
          jsonData: this.j_data_string,
          status: "initial",
        };
        worker.postMessage({ sendMessage });
      }
    },
    async checkForApprovedTags(tags_list) {
      // console.log(tags_list)
      // console.log(this.hasura_params)
      let query = this.buildQuery(this.customer_id, tags_list);
      try {
        const response = await axios.post(
          this.hasura_params.url,
          {
            query: query,
          },
          {
            headers: this.hasura_params.headers,
          }
        );
        const tags = response.data.data.list_animals;
        this.findMatchingTags(tags, tags_list);
      } catch (error) {
        console.log(error);
      }
    },
    findMatchingTags(tags, tags_list) {
      const filterTags = [];

      // const matchingTags = tags.filter((tag) => {
      //   return tags_list.some((imgTag) => {
      //     const labelMatch =
      //       imgTag.tag_label.toLowerCase() === tag.tag_label.toLowerCase();
      //     const tagColorMatch =
      //       (imgTag.tag_color === undefined && tag.tag_color === undefined) ||
      //       (imgTag.tag_color &&
      //         tag.tag_color &&
      //         imgTag.tag_color.toLowerCase() === tag.tag_color.toLowerCase());

      //     return labelMatch && tagColorMatch;
      //   });
      // });
      const matchingTags = tags.filter((tag) => {
        const matchingTag = tags_list.find((imgTag) => {
          const labelMatch =
            imgTag.tag_label.toLowerCase() === tag.tag_label.toLowerCase();
          const tagColorMatch =
            (imgTag.tag_color === undefined && tag.tag_color === undefined) ||
            (imgTag.tag_color &&
              tag.tag_color &&
              imgTag.tag_color.toLowerCase() === tag.tag_color.toLowerCase());
          return labelMatch && tagColorMatch;
        });

        if (matchingTag) {
          tag.tracking_id = matchingTag.tracking_id;
          return true;
        }

        return false;
      });
      if (matchingTags.length > 0) {
        matchingTags.forEach((matchingTag) => {
          let filterTag = matchingTag;
          let imageUrl = matchingTag.image_id
            ? this.cropped_url(
                matchingTag.image_id,
                matchingTag.image_timestamp,
                matchingTag.customer_id,
                "head"
              )
            : "";
          filterTag.imageUrl = imageUrl;
          filterTag.isSelected = false;
          filterTag.imageUrlBody = matchingTag.image_id
            ? this.cropped_url(
                matchingTag.image_id,
                matchingTag.image_timestamp,
                matchingTag.customer_id,
                "body"
              )
            : "";
          filterTags.push(filterTag);
        });
      }

      this.filteredTags = filterTags;
    },
    cropped_url(id, timestamp, customer_id, type) {
      let ymd = moment(timestamp).format("YYYY/MM/DD");
      let total_date = moment(timestamp).format("YYYY-MM-DD_HH-mm-ss");
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${customer_id}/animals/images/${ymd}/${id}_${total_date}_${type}.jpg`;
    },
    buildQuery(customerId, tagLabels) {
      let query = `query {
    list_animals(where: {customer_id: {_eq: "${customerId}"}`;

      if (tagLabels && tagLabels.length > 0) {
        // Include tag labels directly as strings in the array
        const tagsFilter = tagLabels
          .map((label) => `"${label.tag_label}"`)
          .join(", ");
        query += `, tag_label: {_in: [${tagsFilter}]}`;
      }

      query += `}) {
        tag_label
        tag_color
        rfid_current
        sex
        classification
        herd_id
        herd_name
        color
        name
        dob
        species
        breed
        image_timestamp
        image_id
        customer_id
      }}`;

      return query;
    },
    startAnnotations() {
      this.frame_rate = this.total_frames_of_video / this.totalDurationInt;
      this.preprocessedBBOX = {};
      this.processAnnotations("bboxMergerWorker", this.preprocessedBBOX);
    },

    bodyPartsAnnotations() {
      this.frame_rate = this.total_frames_of_video / this.totalDurationInt;
      this.preprocessedBodyparts = {};
      this.processAnnotations(
        "bodypartsMergerWorker",
        this.preprocessedBodyparts
      );
    },

    keypointsAnnotations() {
      this.frame_rate = this.total_frames_of_video / this.totalDurationInt;
      this.preprocessedKeypoints = {};
      this.processAnnotations(
        "keypointsMergerWorker",
        this.preprocessedKeypoints
      );
    },

    updateCanvas() {
      var video = this.$refs.videoPlayer;
      var canvas = this.$refs.videoPlayerCanvas;
      var invisibleCanvas = this.$refs.videoPlayerCanvas2;
      var logo = document.getElementById("logo");
      var ctx = canvas.getContext("2d");
      var ctx2 = invisibleCanvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      if (this.isRecording) {
        ctx2.drawImage(
          video,
          0,
          0,
          invisibleCanvas.width,
          invisibleCanvas.height
        );
      }
      //ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
      //Draw the logo background

      ctx2.fillStyle = "rgba(0, 0, 0, 0.4)";
      if (this.isRecording) {
        ctx2.fillRect(390, 0, 282.3, 121);
        ctx2.drawImage(logo, 400, 0, 262.3, 111);
      }

      if (
        this.customer_logo_orientation.toUpperCase() !== "No-Logo".toUpperCase()
      ) {
        if (
          this.customer_logo_orientation.toUpperCase() ===
          "Top-Left".toUpperCase()
        ) {
          ctx.drawImage(this.cx_logo_image, 10, 30, 200, 50);
        } else if (
          this.customer_logo_orientation.toUpperCase() ===
          "Bottom-Left".toUpperCase()
        ) {
          ctx.drawImage(this.cx_logo_image, 10, canvas.height - 90, 200, 50);
        } else if (
          this.customer_logo_orientation.toUpperCase() ===
          "Top-Right".toUpperCase()
        ) {
          ctx.drawImage(this.cx_logo_image, canvas.width - 210, 30, 200, 50);
        } else {
          ctx.drawImage(
            this.cx_logo_image,
            canvas.width - 210,
            canvas.height - 90,
            200,
            50
          );
        }
      }

      if ((this.showbos || this.activityIcons) && this.is_json) {
        this.drawBBOX(ctx);
        if (this.isRecording) {
          this.drawBBOX(ctx2, false);
        }
      }
      if (this.bodyparts && this.is_json) {
        this.drawBodyParts(ctx);
        if (this.isRecording) {
          this.drawBodyParts(ctx2, false);
        }
      }
      if (this.keypoints && this.is_json) {
        if (this.portalRunning === "admin") {
          this.drawLines(ctx);
        }

        this.drawKeypoints(ctx);
        if (this.isRecording) {
          if (this.portalRunning === "admin") {
            this.drawLines(ctx2, false);
          }

          this.drawKeypoints(ctx2, false);
        }
      }

      ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
      ctx.fillRect(400, 10, 130, 60);
      ctx.drawImage(logo, 400, 0);

      this.animationID = requestAnimationFrame(this.updateCanvas);
    },
    pauseDrawing() {
      cancelAnimationFrame(this.animationID);
    },

    drawKeypoints(ctx, scale = true) {
      const scaleX = (value) => (scale ? this.scaleX(value) : value);
      const scaleY = (value) => (scale ? this.scaleY(value) : value);
      var video = this.$refs.videoPlayer;
      var currentFrame = Math.floor(video.currentTime * this.frame_rate);

      if (currentFrame) {
        var currentFrameData = this.preprocessedKeypoints[currentFrame];
        if (currentFrameData && currentFrameData.length) {
          for (let i = 0; i < currentFrameData.length; i++) {
            const dataPoint = currentFrameData[i];

            if (Object.prototype.hasOwnProperty.call(dataPoint, "keypoints")) {
              const keypoints = dataPoint.keypoints;

              for (let j = 0; j < keypoints.length; j++) {
                const targetKeypoint = keypoints[j];

                if (ctx.strokeStyle !== "rgb" + targetKeypoint.color2) {
                  ctx.strokeStyle = "rgb" + targetKeypoint.color2;
                  ctx.lineWidth = 4;
                }

                const radius = 3;
                ctx.beginPath();
                ctx.arc(
                  scaleX(targetKeypoint.x1),
                  scaleY(targetKeypoint.y1),
                  radius,
                  0,
                  2 * Math.PI
                );
                ctx.stroke();

                ctx.fillStyle = "rgb" + targetKeypoint.color1;
                ctx.fill();
              }
            }
          }
        }
      }
    },

    drawLines(ctx, scale = true) {
      const scaleX = (value) => (scale ? this.scaleX(value) : value);
      const scaleY = (value) => (scale ? this.scaleY(value) : value);
      var video = this.$refs.videoPlayer;
      var currentFrame = Math.floor(video.currentTime * this.frame_rate);
      if (currentFrame) {
        var currentFrameAllBBox = this.preprocessedKeypoints[currentFrame];
        if (currentFrameAllBBox && currentFrameAllBBox.length) {
          for (let i = 0; i < currentFrameAllBBox.length; i++) {
            if (
              Object.prototype.hasOwnProperty.call(
                currentFrameAllBBox[i],
                "lines"
              )
            ) {
              let linesArray = currentFrameAllBBox[i].lines;
              for (let j = 0; j < linesArray.length; j++) {
                let line = linesArray[j];

                ctx.lineWidth = scale ? 1 : 3;
                ctx.strokeStyle = "rgb" + line.linecolor;

                ctx.beginPath();
                ctx.moveTo(scaleX(line.x1), scaleY(line.y1));
                ctx.lineTo(scaleX(line.x2), scaleY(line.y2));
                ctx.stroke();
              }
            }
          }
        }
      }
    },

    fetchNextChunkBBox(startFrame) {
      if (this.bboxWorker) {
        const worker = this.bboxWorker;
        const sendMessage = {
          startFrame: startFrame,
          status: "load",
        };
        worker.postMessage({ sendMessage });
      }
    },

    fetchNextChunkBodyParts(startFrame) {
      if (this.bodypartsWorker) {
        const worker = this.bodypartsWorker;
        const sendMessage = {
          startFrame: startFrame,
          status: "load",
        };
        worker.postMessage({ sendMessage });
      }
    },
    drawIcon(ctx, icon, index, bbox, scaleX, scaleY) {
      const basePosition = 5;
      const offset = 40;
      const position = basePosition + offset * index;

      ctx.beginPath();
      const iconX = scaleX(bbox.x1) + scaleX(bbox.w) - position;
      const iconY = scaleY(bbox.y1) - 25;
      ctx.arc(iconX, iconY, 15, 0, 2 * Math.PI);
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.drawImage(icon, iconX - 25 / 2, iconY - 25 / 2);
    },
    drawStackedText(ctx, text, index, bbox, scaleX, scaleY) {
      ctx.font = "bold 85% Arial";
      ctx.fillStyle = "white";
      ctx.textBaseline = "bottom";
      ctx.textAlign = "right";
      // Calculate text dimensions
      const textWidth = ctx.measureText(text).width;
      const textMetrics = ctx.measureText(text);
      const textHeight =
        textMetrics.actualBoundingBoxAscent +
        textMetrics.actualBoundingBoxDescent;

      const textX = scaleX(bbox.x1) + scaleX(bbox.w) - 5;
      const verticalOffset = 5;
      const textY =
        scaleY(bbox.y1) +
        scaleY(bbox.h) -
        (textHeight + verticalOffset) * index -
        verticalOffset;

      const rectanglePadding = 2;
      const backgroundHeight = textHeight + rectanglePadding * 2;
      const backgroundWidth = textWidth + rectanglePadding * 2;

      ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
      ctx.fillRect(
        textX - textWidth - rectanglePadding,
        textY - textHeight - rectanglePadding - 2,
        backgroundWidth,
        backgroundHeight
      );

      // Draw the text over the background
      ctx.fillStyle = "white"; // Text color
      ctx.fillText(text, textX, textY);
    },
    drawBBOX(ctx, scale = true) {
      const scaleX = (value) => (scale ? this.scaleX(value) : value);
      const scaleY = (value) => (scale ? this.scaleY(value) : value);
      const THRESHOLD = 1000;
      var video = this.$refs.videoPlayer;
      var calving_icon = document.getElementById("calving_cow_image");
      var chinresting_icon = document.getElementById("chinresting_cow_image");
      var mounting_icon = document.getElementById("mounting_cow_image");
      var laying_icon = document.getElementById("laying_cow_image");
      var standing_icon = document.getElementById("standing_cow_image");
      var heat_icon = document.getElementById("heat_cow_image");
      var currentFrame = Math.floor(video.currentTime * this.frame_rate);

      if (currentFrame) {
        if (
          currentFrame + THRESHOLD >
            this.bboxProcessedObjectKeys[
              this.bboxProcessedObjectKeys.length - 1
            ] &&
          !this.isFetchingNextChunkBBox
        ) {
          this.isFetchingNextChunkBBox = true;
          this.fetchNextChunkBBox(currentFrame);
          this.lastBBoxFrameFetched = currentFrame;
        } else if (
          currentFrame < this.lastBBoxFrameFetched - THRESHOLD &&
          !this.isFetchingNextChunkBBox
        ) {
          this.isFetchingNextChunkBBox = true;
          this.fetchNextChunkBBox(currentFrame);
          this.lastBBoxFrameFetched = currentFrame;
        }

        var currentFrameAllBBox = this.preprocessedBBOX[currentFrame];

        if (currentFrameAllBBox) {
          for (let i = 0; i < currentFrameAllBBox.length; i++) {
            // ctx.fillStyle = "rgba(242, 114, 0, 0.4)";

            let bbox = currentFrameAllBBox[i].bbox;

            let tracker = Object.prototype.hasOwnProperty.call(
              currentFrameAllBBox[i],
              "tracking_id"
            )
              ? currentFrameAllBBox[i].tracking_id
              : null;

            let textIndex = 0;
            if (tracker !== null) {
              if (this.selectedTag == tracker) {
                ctx.fillStyle = "rgb(0, 144, 164, 0.3)";
                ctx.strokeStyle = "rgba(0, 144, 164)";
              } else {
                if (this.showbos) {
                  ctx.fillStyle = "rgba(242, 114, 0, 0.4)";
                  ctx.strokeStyle = "rgba(242, 114, 0)";
                } else {
                  ctx.fillStyle = "transparent";
                }
              }
            }

            if (this.bbox_fill) {
              ctx.fillRect(
                scaleX(bbox.x1),
                scaleY(bbox.y1),
                scaleX(bbox.w),
                scaleY(bbox.h)
              );
            }

            ctx.lineWidth = 3;

            ctx.strokeRect(
              scaleX(bbox.x1),
              scaleY(bbox.y1),
              scaleX(bbox.w),
              scaleY(bbox.h)
            );

            if (this.labels) {
              var textWidth;
              var textHeight;
              var textMetrics;
              var bboxRectangleHeight;
              var bboxRectangleWidth;

              ctx.fillStyle = "rgba(29, 107, 118,0.9)";
              var label_x = scaleX(bbox.x1);
              var label_y = scaleY(bbox.y1);
              var label_w = scaleX(bbox.w);

              ctx.font = scale ? `bold 85% Arial` : `bold 130% Arial`;

              //Tracker

              if (tracker !== null) {
                textWidth = ctx.measureText(tracker).width;
                textMetrics = ctx.measureText(tracker);
                textHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent;
                bboxRectangleHeight = textHeight + 1;
                bboxRectangleWidth = textWidth + 1;
                let label_y_pos = scale ? label_y + 3 : label_y + 2;
                let rectangle_width = scale
                  ? bboxRectangleWidth + 3
                  : bboxRectangleWidth + 6;
                let label_x_pos = scale
                  ? label_x + label_w - bboxRectangleWidth - 6
                  : label_x + label_w - bboxRectangleWidth - 8;
                ctx.fillRect(
                  label_x_pos,
                  label_y_pos,
                  rectangle_width,
                  bboxRectangleHeight + 3
                );
                label_y_pos = scale ? label_y + 13 : label_y + 15;
                ctx.fillStyle = "white";
                ctx.fillText(
                  tracker,
                  label_x + label_w - bboxRectangleWidth - 4,
                  label_y_pos
                );
              }

              //bbox Text
              ctx.fillStyle = "rgba(29, 107, 118,0.9)";
              ctx.font = label_w > 100 ? "85%" : "60%";
              let primaryLabel = Object.prototype.hasOwnProperty.call(
                currentFrameAllBBox[i],
                "label"
              )
                ? currentFrameAllBBox[i].label
                : null;
              let primaryLabel_conf = Object.prototype.hasOwnProperty.call(
                currentFrameAllBBox[i],
                "conf"
              )
                ? currentFrameAllBBox[i].conf
                : null;
              let classifcation = Object.prototype.hasOwnProperty.call(
                currentFrameAllBBox[i],
                "classification"
              )
                ? currentFrameAllBBox[i].classification
                : null;

              var bboxText = "";

              if (primaryLabel && primaryLabel !== "") {
                let formattedPrimarylabel =
                  primaryLabel.charAt(0).toUpperCase() + primaryLabel.slice(1);
                bboxText += `${formattedPrimarylabel}: ${primaryLabel_conf} `;
              }

              if (
                classifcation &&
                classifcation.label &&
                classifcation.label !== ""
              ) {
                let label = classifcation.label;
                let conf = classifcation.conf;
                let formattedLabel =
                  label.charAt(0).toUpperCase() + label.slice(1);
                bboxText += `${formattedLabel}: ${conf}`;
              }
              if (bboxText !== "") {
                textWidth = ctx.measureText(bboxText).width;
                textMetrics = ctx.measureText(bboxText);
                textHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent;
                bboxRectangleHeight = textHeight + 1;
                bboxRectangleWidth = textWidth + 1;

                ctx.fillRect(
                  label_x,
                  label_y - 7 - textHeight,
                  bboxRectangleWidth + 3,
                  bboxRectangleHeight + 3
                );

                ctx.fillStyle = "white";
                ctx.fillText(bboxText, label_x + 2, label_y - 6);
              }
            }
            let iconIndex = 0;

            //Age classfication
            // ctx.fillStyle = "#56c759";
            if (
              "age_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].age_classification != null &&
              "label" in currentFrameAllBBox[i].age_classification &&
              currentFrameAllBBox[i].age_classification.label != null &&
              "conf" in currentFrameAllBBox[i].age_classification &&
              currentFrameAllBBox[i].age_classification.conf != null
            ) {
              this.isAgePresent = true;
              var ageLabel = currentFrameAllBBox[i].age_classification.label;
              var ageLabelConf = currentFrameAllBBox[i].age_classification.conf;
              var formattedAgeLabel =
                ageLabel.charAt(0).toUpperCase() + ageLabel.slice(1);

              if (formattedAgeLabel.toUpperCase() === "Cow".toUpperCase()) {
                formattedAgeLabel = "Adult";
              }
              var ageLabelText = `Age: ${formattedAgeLabel}, ${ageLabelConf}`;

              if (this.isAge && this.labels) {
                this.drawStackedText(
                  ctx,
                  ageLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            if (
              "gender_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].gender_classification != null &&
              "label" in currentFrameAllBBox[i].gender_classification &&
              currentFrameAllBBox[i].gender_classification.label != null &&
              "conf" in currentFrameAllBBox[i].gender_classification &&
              currentFrameAllBBox[i].gender_classification.conf != null
            ) {
              this.isGenderPresent = true;
              var genderLabel =
                currentFrameAllBBox[i].gender_classification.label;
              var genderLabelConf =
                currentFrameAllBBox[i].gender_classification.conf;
              var formattedGenderLabel =
                genderLabel.charAt(0).toUpperCase() + genderLabel.slice(1);

              var genderLabelText = `Gender: ${formattedGenderLabel}, ${genderLabelConf}`;

              if (this.isGender && this.labels) {
                this.drawStackedText(
                  ctx,
                  genderLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            if (
              "grazing_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].grazing_classification != null &&
              "label" in currentFrameAllBBox[i].grazing_classification &&
              currentFrameAllBBox[i].grazing_classification.label != null &&
              "conf" in currentFrameAllBBox[i].grazing_classification &&
              currentFrameAllBBox[i].grazing_classification.conf != null
            ) {
              this.isGrazingPresent = true;
              var grazingLabel =
                currentFrameAllBBox[i].grazing_classification.label;
              var grazingLabelConf =
                currentFrameAllBBox[i].grazing_classification.conf;
              var formattedGrazingLabel =
                grazingLabel.charAt(0).toUpperCase() + grazingLabel.slice(1);

              var grazingLabelText = `Gender: ${formattedGrazingLabel}, ${grazingLabelConf}`;

              if (this.isGrazing && this.labels) {
                this.drawStackedText(
                  ctx,
                  grazingLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            if (
              this.isActivityPresent &&
              "activity_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].activity_classification &&
              "label" in currentFrameAllBBox[i].activity_classification &&
              currentFrameAllBBox[i].activity_classification.label
            ) {
              if (this.activityIcons && this.isActivity) {
                this.drawIcon(
                  ctx,
                  currentFrameAllBBox[i].activity_classification.label ===
                    "standing_walking"
                    ? standing_icon
                    : laying_icon,
                  iconIndex,
                  bbox,
                  scaleX,
                  scaleY
                );

                iconIndex++;
              }

              let activityLabel = currentFrameAllBBox[
                i
              ].activity_classification.label
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(
                  currentFrameAllBBox[i].activity_classification.label.includes(
                    "standing"
                  )
                    ? "/"
                    : " "
                );

              if (this.labels && this.isActivity) {
                let activityConf =
                  currentFrameAllBBox[i].activity_classification.conf;
                let activityLabelText = `Activity: ${activityLabel}${
                  activityConf ? ` ${activityConf}` : ""
                }`;
                this.drawStackedText(
                  ctx,
                  activityLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            //Resting Chin
            if (
              this.chinRestingEventPresent &&
              "chin_resting_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].chin_resting_classification &&
              "label" in currentFrameAllBBox[i].chin_resting_classification &&
              currentFrameAllBBox[i].chin_resting_classification.label ===
                "resting_chin"
            ) {
              if (this.activityIcons && this.chinRestingEvents) {
                this.drawIcon(
                  ctx,
                  chinresting_icon,
                  iconIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                iconIndex++;
              }

              if (this.chinRestingEvents && this.labels) {
                let restingChinConf =
                  currentFrameAllBBox[i].chin_resting_classification.conf;
                let restingChinLabelText = `Chin Resting: ${restingChinConf}%`;
                this.drawStackedText(
                  ctx,
                  restingChinLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            //Mounting
            if (
              this.mountingEventPresent &&
              "mounting_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].mounting_classification &&
              "label" in currentFrameAllBBox[i].mounting_classification &&
              currentFrameAllBBox[i].mounting_classification.label !==
                "not_mounting"
            ) {
              if (this.activityIcons && this.mountingEvents) {
                this.drawIcon(
                  ctx,
                  mounting_icon,
                  iconIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                iconIndex++;
              }

              if (this.labels && this.mountingEvents) {
                let mountingLabelText = `Mounting: ${currentFrameAllBBox[i].mounting_classification.conf}%`;
                this.drawStackedText(
                  ctx,
                  mountingLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            if (
              this.calvingEventPresent &&
              "calving_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].calving_classification &&
              "label" in currentFrameAllBBox[i].calving_classification &&
              currentFrameAllBBox[i].calving_classification.label === "tail_up"
            ) {
              if (this.activityIcons && this.calvingEvents) {
                this.drawIcon(
                  ctx,
                  calving_icon,
                  iconIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                iconIndex++;
              }

              if (this.labels && this.calvingEvents) {
                let deliveringLabelText = `Tail Up: ${currentFrameAllBBox[i].calving_classification.conf}`;
                this.drawStackedText(
                  ctx,
                  deliveringLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            //in Heat
            if (
              this.standingHeatEventPresent &&
              "heat_classification" in currentFrameAllBBox[i] &&
              currentFrameAllBBox[i].heat_classification &&
              "label" in currentFrameAllBBox[i].heat_classification &&
              currentFrameAllBBox[i].heat_classification.label === "in_heat"
            ) {
              if (this.activityIcons && this.standingHeatEvents) {
                this.drawIcon(ctx, heat_icon, iconIndex, bbox, scaleX, scaleY);
                iconIndex++;
              }

              if (this.labels && this.standingHeatEventPresent) {
                let deliveringLabelText = `In Heat: ${currentFrameAllBBox[i].heat_classification.conf}%`;
                this.drawStackedText(
                  ctx,
                  deliveringLabelText,
                  textIndex,
                  bbox,
                  scaleX,
                  scaleY
                );
                textIndex++;
              }
            }

            ctx.textAlign = "start";
            ctx.textBaseline = "alphabetic";
          }
        }
      }
    },

    drawBodyParts(ctx, scale = true) {
      const scaleX = (value) => (scale ? this.scaleX(value) : value);
      const scaleY = (value) => (scale ? this.scaleY(value) : value);
      var video = this.$refs.videoPlayer;
      var currentFrame = Math.floor(video.currentTime * this.frame_rate);
      const THRESHOLD = 1000;

      if (currentFrame) {
        if (
          currentFrame + THRESHOLD >
            this.bodyPartsProcessedObjectKeys[
              this.bodyPartsProcessedObjectKeys.length - 1
            ] &&
          !this.isFetchingNextChunkBodyParts
        ) {
          this.isFetchingNextChunkBodyParts = true;
          this.fetchNextChunkBodyParts(currentFrame);
          this.lastBodyPartsFrameFetched = currentFrame;
        } else if (
          currentFrame < this.lastBodyPartsFrameFetched - THRESHOLD &&
          !this.isFetchingNextChunkBodyParts
        ) {
          this.isFetchingNextChunkBodyParts = true;
          this.fetchNextChunkBodyParts(currentFrame);
          this.lastBodyPartsFrameFetched = currentFrame;
        }
        var currentFrameAllBBox = this.preprocessedBodyparts[currentFrame];
        if (currentFrameAllBBox) {
          for (let i = 0; i < currentFrameAllBBox.length; i++) {
            let animal = currentFrameAllBBox[i];
            let bp_id = animal.bodyparts_id;
            let bp = animal.bodyparts;
            if (bp && bp_id) {
              let concatenatedArray = bp_id.concat(bp);
              if (concatenatedArray.length) {
                for (let j = 0; j < concatenatedArray.length; j++) {
                  let bbox = concatenatedArray[j].bbox;
                  let label = concatenatedArray[j].label;
                  let id = concatenatedArray[j].id;
                  let conf = concatenatedArray[j].conf;
                  ctx.fillStyle = "rgba(0, 154, 255, 0.3)";
                  if (label == "tag") {
                    ctx.fillStyle = "rgba(55, 255, 255, 0.4)";
                  } else if (label == "head") {
                    ctx.fillStyle = "rgba(0, 154, 255, 0.4)";
                  } else if (label == "knee") {
                    ctx.fillStyle = "rgba(254, 255, 0, 0.4)";
                  } else if (label == "hoof") {
                    ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
                  } else if (label == "tail") {
                    ctx.fillStyle = "rgba(255, 66, 161, 0.4)";
                  } else {
                    ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
                  }
                  ctx.fillRect(
                    scaleX(bbox.x1),
                    scaleY(bbox.y1),
                    scaleX(bbox.w),
                    scaleY(bbox.h)
                  );
                  // border modifications
                  ctx.lineWidth = 3;
                  if (label == "tag") {
                    ctx.strokeStyle = "rgba(55, 255, 255, 0.4)";
                  } else if (label == "head") {
                    ctx.strokeStyle = "rgba(0, 154, 255, 0.4)";
                  } else if (label == "knee") {
                    ctx.strokeStyle = "rgba(254, 255, 0, 0.4)";
                  } else if (label == "hoof") {
                    ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
                  } else if (label == "tail") {
                    ctx.strokeStyle = "rgba(255, 66, 161, 0.4)";
                  } else {
                    ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
                  }
                  ctx.strokeRect(
                    scaleX(bbox.x1),
                    scaleY(bbox.y1),
                    scaleX(bbox.w),
                    scaleY(bbox.h)
                  );

                  if (this.labels) {
                    ctx.fillStyle = "rgba(29, 107, 118,0.9)";
                    var bodyPartsLabel = label;
                    var formattedBodyLabel =
                      bodyPartsLabel.charAt(0).toUpperCase() +
                      bodyPartsLabel.slice(1);
                    var bodyPartsText;
                    if (formattedBodyLabel == "Tag") {
                      let id_key = id.toString();
                      if (this.reverseTrackedObjects.has(id_key)) {
                        let value = this.reverseTrackedObjects.get(id_key);
                        bodyPartsText = `${value.ocr_read}, ${value.conf}`;
                      } else {
                        bodyPartsText = formattedBodyLabel + "," + conf;
                      }
                    } else {
                      bodyPartsText = formattedBodyLabel + "," + conf;
                    }

                    var textWidth;
                    var textHeight;
                    var textMetrics;

                    var label_x = scaleX(bbox.x1);
                    var label_y = scaleY(bbox.y1);

                    ctx.font = scale ? `bold 70% Arial` : `bold 100% Arial`;
                    textWidth = ctx.measureText(bodyPartsText).width;
                    textMetrics = ctx.measureText(bodyPartsText);
                    textHeight =
                      textMetrics.actualBoundingBoxAscent +
                      textMetrics.actualBoundingBoxDescent;
                    const bodyPartsRectangleHeight = textHeight;
                    const bodyPartsRectangleWidth = textWidth;
                    ctx.fillRect(
                      label_x,
                      label_y - textHeight - 5,
                      bodyPartsRectangleWidth + 3,
                      bodyPartsRectangleHeight + 3
                    );
                    ctx.fillStyle = "white";
                    ctx.fillText(bodyPartsText, label_x + 2, label_y - 5);
                  }
                }
              }
            }
          }
        }
      }
    },
    drawLogo(item) {
      this.customer_logo_orientation = item;
    },
    startRecording() {
      if (!this.recording) {
        this.isRecording = true;
        this.recording = true;
        var canvas = document.querySelector("#invisibleCanvas");
        var videoStream = canvas.captureStream(30);
        this.mediaRecorder = new MediaRecorder(videoStream, {
          mimeType: "video/webm; codecs=vp9",
          //mimeType: "video/mp4; codecs=avc1.42E01E",
        });
        this.mediaRecorder.start();
        this.mediaRecorder.ondataavailable = function (e) {
          this.chunks.push(e.data);
        }.bind(this);

        this.intervalID = setInterval(() => {
          this.record_second += 1;
        }, 1000);
      }
    },
    stopRecording() {
      this.isRecording = false;
      if (this.recording && this.mediaRecorder) {
        this.recording = false;
        this.mediaRecorder.stop();
        clearInterval(this.intervalID);
        this.player.pause();
        this.mediaRecorder.onstop = function () {
          var blob = new Blob(this.chunks, { type: "video/webm" });
          var videoURL = URL.createObjectURL(blob);
          var a = document.createElement("a");
          const fileName = window.prompt("Enter name to save file: ");
          a.download = `${fileName}.webm`;
          a.href = videoURL;
          a.click();
        }.bind(this);
        this.record_second = 0;
        this.clearList(this.chunks);
        this.mediaRecorder = null;
      }
    },
    toggle() {
      this.front_image_select = !this.front_image_select;
    },
    formatString(str) {
      const strLower = str.toLowerCase();
      const strReq = strLower.charAt(0).toUpperCase() + strLower.slice(1);
      return strReq;
    },
    resetTags() {
      this.displayingOneAnimal = false;
      this.filteredTags.forEach((tag) => {
        tag.isSelected = false;
      });
      if (this.displayCurrentAnimal) {
        this.displayCurrentAnimal = {};
      }
    },
    displayAnimal(animal) {
      this.resetTags();
      const tagIndex = this.filteredTags.findIndex((tag) => tag === animal);
      if (tagIndex !== -1) {
        this.filteredTags[tagIndex].isSelected = true;

        // Set the display flags and the current animal
        this.displayingOneAnimal = true;
        this.displayCurrentAnimal = this.filteredTags[tagIndex];
        this.selectTag(this.filteredTags[tagIndex].tracking_id);
      }
    },
    sortBy(prop) {
      this.filteredTags.sort((a, b) => {
        const propA = a[prop].toLowerCase();
        const propB = b[prop].toLowerCase();

        if (propA < propB) return -1;
        if (propA > propB) return 1;
        return 0; // If the properties are equal
      });
    },
    showOriginal() {
      this.displayingOneAnimal = false;
      this.resetTags();
      // this.listTop5Animals = this.copyListTop5Animals;
    },
    myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    prevFrame() {
      this.player.markers.prev();
    },
    nextFrame() {
      this.player.markers.next();
    },
    cancelFrame() {
      this.player.markers.removeAll();
      this.selectedTag = -1;
      this.resetTags();
    },
    averageCalculator(array) {
      let nA = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i + 6]) {
          let avg =
            (array[i] +
              array[i + 1] +
              array[i + 2] +
              array[i + 3] +
              array[i + 4] +
              array[i + 5] +
              array[i + 6]) /
            7;
          nA.push(Math.round(avg));
        } else {
          nA.push(array[i]);
        }
      }
      return nA;
    },
    xForm(array) {
      let newArray = [];
      for (let i = 0; i < array.length; i++) {
        newArray.push(i);
      }
      return newArray;
    },
    modifyArray(array) {
      let newArray = [];
      let itemToPush;
      for (let i = 0; i < array.length; i++) {
        let checkItem = array[i];
        if (checkItem !== itemToPush) {
          itemToPush = checkItem;
          newArray.push(itemToPush);
        }
      }
      return newArray;
    },
    async showGraph(json) {
      if (Object.prototype.hasOwnProperty.call(json, "frame")) {
        let frameArray = json["frame"];
        this.fillGraphChartValues(frameArray);
      }
      this.showGraphvar = true;
    },
    generateGraph(xArray, yArray) {
      const chart = document.getElementById("myChart");
      const maxY = Math.max(...yArray);
      this.graphChart = new Chart(chart, {
        type: "line",
        data: {
          labels: xArray,
          datasets: [
            {
              backgroundColor: "rgba(192,192,192,1.0)",
              borderColor: "rgba(0,0,255,0.1)",
              cubicInterpolationMode: "bezier",
              data: yArray,
            },
          ],
        },
        options: {
          elements: {
            line: {
              tension: 0.6,
            },
            point: {
              radius: 0,
            },
          },
          legend: { display: false },
          scales: {
            yAxes: [
              {
                ticks: {
                  display: false,
                  beginAtZero: true,
                  max: Math.round(maxY * 2),
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    },
    scaleX(coord) {
      return Math.round(coord * (952 / this.videoWidth));
    },
    scaleY(coord) {
      return Math.round(coord * (535.5 / this.videoHeight));
    },
    area(j_data_element) {
      let bbox = j_data_element["bbox"];
      return bbox["w"] * bbox["h"];
    },
    timeUpdate() {
      const timelineContainer = document.querySelector(".timeline-container");
      var videoPlayer = document.querySelector("#videoPlayer");
      this.currentTime = this.formatTime(videoPlayer.player.currentTime());
      const percent =
        videoPlayer.player.currentTime() / videoPlayer.player.duration();
      timelineContainer.style.setProperty("--progress-bar", percent);
    },
    toggleScrubbing(e) {
      const timelineContainer = document.querySelector(".timeline-container");
      const videoContainer = document.querySelector(".video-container");
      const videoPlayer = document.querySelector("#videoPlayer");
      const bound = timelineContainer.getBoundingClientRect();
      const percent =
        Math.min(Math.max(0, e.x - bound.x), bound.width) / bound.width;
      this.isScrubbing = (e.buttons & 1) === 1;
      videoContainer.classList.toggle("scrubbing", this.isScrubbing);
      if (this.isScrubbing) {
        this.isPaused = videoPlayer.player.paused();
        videoPlayer.player.pause();
        videoPlayer.player.currentTime(percent * videoPlayer.player.duration());
      }
      // } else {
      //   if (!this.isPaused) {
      //     console.log("toggle scrubbing")
      //     videoPlayer.player.play();
      //   }
      // }
      this.handleTimeline(e);
      // this.updateCanvas();
    },
    handleTimeline(e) {
      // whenever the mouse is hovered or moved this is fired
      const videoPlayer = document.querySelector("#videoPlayer");
      const timelineContainer = document.querySelector(".timeline-container");
      const previewImg = document.querySelector(".preview-img");
      const thumbnailImg = document.querySelector(".thumbnail-img");
      const bound = timelineContainer.getBoundingClientRect();
      const percent =
        Math.min(Math.max(0, e.x - bound.x), bound.width) / bound.width;
      // eslint-disable-next-line no-unused-vars
      const previewImgNumber = Math.max(
        1,
        Math.floor((percent * videoPlayer.player.duration()) / 10)
      );
      const thumbnailKeys = Object.keys(this.thumbnailArray);
      const reqKey = thumbnailKeys[previewImgNumber];
      const previewImgSrc = this.thumbnailArray[reqKey]["src"];
      previewImg.src = previewImgSrc;
      timelineContainer.style.setProperty("--preview-bar", percent);
      if (this.isScrubbing) {
        // mouse clicked and moved on progress slider
        e.preventDefault();
        thumbnailImg.src = previewImgSrc;
        timelineContainer.style.setProperty("--progress-bar", percent);
        videoPlayer.player.currentTime(percent * videoPlayer.player.duration());
      }
    },
    callPercent(percent_number) {
      const videoPlayer = document.querySelector("#videoPlayer");
      const waitImg = document.querySelector(".wait-img");
      const previewImgNumber = Math.max(
        1,
        Math.floor((percent_number * videoPlayer.player.duration()) / 10)
      );
      const thumbnailKeys = Object.keys(this.thumbnailArray);
      const reqKey = thumbnailKeys[previewImgNumber];
      if (this.thumbnailArray[reqKey]) {
        waitImg.src = this.thumbnailArray[reqKey]["src"];
      }
      // waitImg.src = this.thumbnailArray[reqKey]["src"];
      waitImg.classList.remove("hide");
    },
    moveTime(seconds) {
      const videoPlayer = document.querySelector("#videoPlayer");
      videoPlayer.player.currentTime(
        videoPlayer.player.currentTime() + seconds
      );
    },
    formatTime(time) {
      const twoDigits = new Intl.NumberFormat(undefined, {
        minimumIntegerDigits: 2,
      });
      const seconds = Math.floor(time % 60);
      const mins = Math.floor(time / 60) % 60;
      const hours = Math.floor(time / 3600);
      if (!hours) {
        return `${mins}:${twoDigits.format(seconds)}`;
      } else {
        return `${hours}:${twoDigits.format(mins)}:${twoDigits.format(
          seconds
        )}`;
      }
    },
    changePlayback() {
      var player = document.querySelector("#videoPlayer");
      let newplayback;
      newplayback = player.player.playbackRate() * 2;
      if (newplayback > 8) {
        newplayback = 0.5;
      }

      this.playbackRate = newplayback;
      player.player.playbackRate(this.playbackRate);
    },
    togglePlay() {
      this.isPlaying ? this.player.play() : this.player.pause();
      this.isPlaying = !this.isPlaying;
    },
    zoomIn() {
      this.panzoom.zoomIn();
    },
    zoomOut() {
      this.panzoom.zoomOut();
    },

    update_options(event) {
      if (event.data.option == "bodyparts") {
        this.bodyparts = event.data.value;
      } else if (event.data.option == "keypoints") {
        this.keypoints = event.data.value;
      } else if (event.data.option == "showbos") {
        this.showbos = event.data.value;
      }
    },
  },
  computed: {
    isAdminPortal() {
      return this.portalRunning === "admin";
    },
    listTop5Animals() {
      return [...this.listAllAnimals].splice(0, 5);
    },
    hasura_params() {
      return this.$store.getters.getHasuraParams;
    },
    filteredSearchTags() {
      if (!this.search) {
        return this.filteredTags;
      }
      const searchLower = this.search.toLowerCase();
      return this.filteredTags.filter((tag) => {
        const labelMatch = tag.tag_label.toLowerCase().includes(searchLower);
        const colorMatch =
          tag.tag_color && tag.tag_color.toLowerCase().includes(searchLower);
        return labelMatch || colorMatch;
      });
    },
    logo_image() {
      if (!this.isAdminPortal) {
        const user_id = this.$store.getters.getCustomerID;
        const source = `https://cdn.onecup.ai/${user_id}/company/images/${user_id}_logo.jpg`;
        return source;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    this.customPlay = true;
    const videoContainer = document.querySelector(".video-container");
    const videoPlayer = document.querySelector("#videoPlayer");
    const videoControlsContainer = document.querySelector(
      ".video-controls-container"
    );
    this.cx_logo_image.src = this.logo_image;
    //this.updateCanvas();

    document.addEventListener("mouseup", (e) => {
      if (this.isScrubbing) this.toggleScrubbing(e);
    });
    document.addEventListener("mousemove", (e) => {
      if (this.isScrubbing) this.handleTimeline(e);
    });
    videoPlayer.addEventListener("loadeddata", () => {
      const loaderDiv = document.querySelector(".overlay-video");
      this.totalDuration = formatTime(videoPlayer.duration);
      this.totalDurationInt = videoPlayer.duration;
      this.frame_rate = this.total_frames_of_video / this.totalDurationInt;
      videoControlsContainer.classList.remove("hide");
      loaderDiv.classList.add("hide");
    });

    function formatTime(time) {
      const twoDigits = new Intl.NumberFormat(undefined, {
        minimumIntegerDigits: 2,
      });
      const seconds = Math.ceil(time % 60);
      const mins = Math.floor(time / 60) % 60;
      const hours = Math.floor(time / 3600);
      if (!hours) {
        return `${mins}:${twoDigits.format(seconds)}`;
      } else {
        return `${hours}:${twoDigits.format(mins)}:${twoDigits.format(
          seconds
        )}`;
      }
    }
    videoPlayer.addEventListener("play", () => {
      videoContainer.classList.remove("paused");
    });
    videoPlayer.addEventListener("pause", () => {
      this.pauseDrawing();
      videoContainer.classList.add("paused");
    });
    this.panzoom = Panzoom(document.querySelector(".panZoom-container"), {
      contain: "outside",
    });
    document
      .querySelector(".panZoom-container")
      .addEventListener("wheel", this.panzoom.zoomWithWheel);
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.ready(() => {
        eventBus.$emit("player_ready");
        this.updateCanvas();
      });

      this.player.on("play", () => {
        this.updateCanvas();
        // this.checkFirstChunks();
        // console.log(this.allFirstChunksLoaded)
        // if (this.allFirstChunksLoaded) {
        //     this.updateCanvas();
        //     return;
        // }

        // // if (this.isDataAvailable) {
        // //   return;
        // // }
        // var video = this.$refs.videoPlayer;
        // const loaderDiv = document.querySelector(".overlay-video");
        // video.pause();
        // loaderDiv.classList.remove("hide"); // Show loader
        // const checkPlayability = () => {
        //     if (this.allFirstChunksLoaded) {
        //       this.updateCanvas();
        //         video.play();
        //         loaderDiv.classList.add("hide");
        //     } else {
        //         setTimeout(checkPlayability, 500);
        //     }
        //   };

        //   checkPlayability();
      });

      // this.player.on("play", () => {
      //   var video = this.$refs.videoPlayer;
      //   const loaderDiv = document.querySelector(".overlay-video");
      //   // this.initialVideoValue=1;
      //   this.annotations_calculated = false;
      //   this.updateCanvas();
      //   console.log("play");
      // });

      this.player.on("ended", () => {
        // this.killAnnotationsGlobal();
      });

      this.player.on("seeking", () => {
        const loaderDiv = document.querySelector(".overlay-video");
        loaderDiv.classList.remove("hide");
        // this.killAnnotationsGlobal();
        const percent = this.player.currentTime() / this.player.duration();
        this.callPercent(percent);
        //this.updateCanvas()
        this.player.pause();
      });

      this.player.on("seeked", () => {
        //var video = this.$refs.videoPlayer;
        const waitImg = document.querySelector(".wait-img");
        //const loaderDiv = document.querySelector(".overlay-video");
        waitImg.classList.add("hide");
        this.player.play();
        // const checkDataAvailability = () => {
        //   //const currentFrameKeypoints = this.preprocessedKeypoints[currentFrame];

        //   const currentFrame = Math.floor(video.currentTime * this.frame_rate);
        //   const currentFrameBBox = this.preprocessedBBOX[currentFrame];
        //   const currentFrameBodyParts =
        //     this.preprocessedBodyparts[currentFrame];

        //   if (
        //     typeof currentFrameBBox === "undefined" ||
        //     typeof currentFrameBodyParts === "undefined"
        //   ) {
        //     setTimeout(checkDataAvailability, 500);
        //   } else {
        //     this.updateCanvas();
        //     video.play();
        //     loaderDiv.classList.add("hide");
        //   }
        // };

        // checkDataAvailability();
        // this.updateCanvas();
      });

      this.player.on("playing", () => {
        // const waitImg = document.querySelector(".wait-img");
        const loaderDiv = document.querySelector(".overlay-video");
        const maskSourceChange = document.querySelector(".mask-source");
        loaderDiv.classList.add("hide");
        // waitImg.classList.add("hide");
        maskSourceChange.classList.add("hide");
      });

      this.player.on("error", () => {
        this.isMediaAbortError = true;
      });

      this.player.on("pause", () => {
        clearInterval(this.my_time);
        this.customPlay = false;
      });
      this.player.markers({
        markerStyle: {
          width: "1px",
          "border-radius": "50%",
          "background-color": "#fbc02d",
        },
        markers: [],
      });
      var overlay_content = "";
      this.player.overlay({
        content: overlay_content,
        debug: true,
        align: "bottom-right",
      });
    });
    document.addEventListener("keydown", (e) => {
      switch (e.key.toLowerCase()) {
        case " ":
          this.togglePlay();
          break;
        case "arrowleft":
          this.moveTime(-5);
          break;
        case "arrowright":
          this.moveTime(5);
          break;
      }
    });
    this.mountGraph();
  },
  created() {
    console.log("here ");
    eventBus.$on("sourceChanged", (video_link) => {
      this.source = video_link;
      this.isShowbos = this.showbos;
      this.isActivityIcons = this.activityIcons;
      this.isKeypoints = this.keypoints;
      this.isBodyparts = this.bodyparts;
      this.isLabels = this.labels;
      this.isbbox_fill = this.bbox_fill;
      this.bbox_fill = false;
      this.bbox_fill = this.isbbox_fill;

      this.showbos = false;
      this.keypoints = false;
      this.bodyparts = false;
      this.labels = false;
      this.customPlay = false;

      this.initialVideoValue = 1;
      //this.updateCanvas();

      this.showbos = this.isShowbos;
      this.keypoints = this.isKeypoints;
      this.activityIcons = this.isActivityIcons;
      this.bodyparts = this.isBodyparts;
      this.labels = this.isLabels;
    });

    eventBus.$on("thumbnailPicArray", (v_tn) => {
      this.thumbnailArray = v_tn;
    });

    window.addEventListener("message", this.update_options, false);
    (function () {
      var defaults = {
          0: {
            src: "https://picsum.photos/id/11/500/300",
          },
        },
        extend = function () {
          var args, target, i, object, property;
          args = Array.prototype.slice.call(arguments);
          target = args.shift() || {};
          for (i in args) {
            object = args[i];
            for (property in object) {
              if (Object.prototype.hasOwnProperty.call(object, property)) {
                if (typeof object[property] === "object") {
                  target[property] = extend(target[property], object[property]);
                } else {
                  target[property] = object[property];
                }
              }
            }
          }
          return target;
        },
        getComputedStyle = function (el, pseudo) {
          return function (prop) {
            if (window.getComputedStyle) {
              return window.getComputedStyle(el, pseudo)[prop];
            } else {
              return el.currentStyle[prop];
            }
          };
        },
        offsetParent = function (el) {
          if (
            el.nodeName !== "HTML" &&
            getComputedStyle(el)("position") === "static"
          ) {
            return offsetParent(el.offsetParent);
          }
          return el;
        },
        getVisibleWidth = function (el, width) {
          var clip;
          if (width) {
            return parseFloat(width);
          }
          clip = getComputedStyle(el)("clip");
          if (clip !== "auto" && clip !== "inherit") {
            clip = clip.split(/(?:\(|\))/)[1].split(/(?:,| )/);
            if (clip.length === 4) {
              return parseFloat(clip[1]) - parseFloat(clip[3]);
            }
          }
          return 0;
        },
        getScrollOffset = function () {
          if (window.pageXOffset) {
            return {
              x: window.pageXOffset,
              y: window.pageYOffset,
            };
          }
          return {
            x: document.documentElement.scrollLeft,
            y: document.documentElement.scrollTop,
          };
        };
      /**
       * register the thubmnails plugin
       */
      videojs.registerPlugin("thumbnails", function (options) {
        var div,
          settings,
          img,
          player,
          progressControl,
          duration,
          moveListener,
          moveCancel;
        settings = extend({}, defaults, options);
        player = this;
        (function () {
          var progressControl, addFakeActive, removeFakeActive;
          // Android doesn't support :active and :hover on non-anchor and non-button elements
          // so, we need to fake the :active selector for thumbnails to show up.
          if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
            progressControl = player.controlBar.progressControl;
            addFakeActive = function () {
              progressControl.addClass("fake-active");
            };
            removeFakeActive = function () {
              progressControl.removeClass("fake-active");
            };
            progressControl.on("touchstart", addFakeActive);
            progressControl.on("touchend", removeFakeActive);
            progressControl.on("touchcancel", removeFakeActive);
          }
        })();
        // create the thumbnail
        div = document.createElement("div");
        div.className = "vjs-thumbnail-holder";
        img = document.createElement("img");
        div.appendChild(img);
        img.src = settings["0"].src;
        img.className = "vjs-thumbnail";
        extend(img.style, settings["0"].style);
        // center the thumbnail over the cursor if an offset wasn't provided
        if (!img.style.left && !img.style.right) {
          img.onload = function () {
            img.style.left = -(img.naturalWidth / 2) + "px";
          };
        }
        // keep track of the duration to calculate correct thumbnail to display
        duration = player.duration();
        // when the container is MP4
        player.on("durationchange", function () {
          duration = player.duration();
        });
        // when the container is HLS
        player.on("loadedmetadata", function () {
          duration = player.duration();
        });
        // add the thumbnail to the player
        progressControl = player.controlBar.progressControl;
        progressControl.el().appendChild(div);
        moveListener = function (event) {
          var mouseTime,
            time,
            active,
            left,
            setting,
            pageX,
            right,
            width,
            halfWidth,
            pageXOffset,
            clientRect;
          active = 0;
          pageXOffset = getScrollOffset().x;
          clientRect = offsetParent(
            progressControl.el()
          ).getBoundingClientRect();
          right = (clientRect.width || clientRect.right) + pageXOffset;
          pageX = event.pageX;
          if (event.changedTouches) {
            pageX = event.changedTouches[0].pageX;
          }
          // find the page offset of the mouse
          left =
            pageX ||
            event.clientX +
              document.body.scrollLeft +
              document.documentElement.scrollLeft;
          // subtract the page offset of the positioned offset parent
          left -=
            offsetParent(progressControl.el()).getBoundingClientRect().left +
            pageXOffset;
          // apply updated styles to the thumbnail if necessary
          // mouseTime is the position of the mouse along the progress control bar
          // `left` applies to the mouse position relative to the player so we need
          // to remove the progress control's left offset to know the mouse position
          // relative to the progress control
          mouseTime = Math.floor(
            ((left - progressControl.el().offsetLeft) /
              progressControl.width()) *
              duration
          );
          for (time in settings) {
            if (mouseTime > time) {
              active = Math.max(active, time);
            }
          }
          setting = settings[active];
          if (setting.src && img.src != setting.src) {
            img.src = setting.src;
          }
          if (setting.style && img.style != setting.style) {
            extend(img.style, setting.style);
          }
          width = getVisibleWidth(img, setting.width || settings[0].width);
          halfWidth = width / 2;
          // make sure that the thumbnail doesn't fall off the right side of the left side of the player
          if (left + halfWidth > right) {
            left -= left + halfWidth - right;
          } else if (left < halfWidth) {
            left = halfWidth;
          }
          div.style.left = left + "px";
        };
        // update the thumbnail while hovering
        progressControl.on("mousemove", moveListener);
        progressControl.on("touchmove", moveListener);
        moveCancel = function () {
          div.style.left = "-1000px";
        };
        // move the placeholder out of the way when not hovering
        progressControl.on("mouseout", moveCancel);
        progressControl.on("touchcancel", moveCancel);
        progressControl.on("touchend", moveCancel);
        player.on("userinactive", moveCancel);
      });
    })();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationID);
    if (this.customPlay == true && this.initialVideoValue != 1) {
      this.customPlay = false;
      this.initialVideoValue = 1;
      // this.updateCanvas();
    }
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style
  src="../../../node_modules/video.js/dist/video-js.css"
  lang="css"
></style>
<style scoped lang="css">
@import "../../../node_modules/video.js/dist/video-js.css";
@import "../../../node_modules/videojs-overlay/dist/videojs-overlay.css";
.box {
  width: 100px;
  height: 50px;
  background: red;
  left: 0;
  top: 0;
}
.video-js .vjs-overlay-background {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0px;
  padding: 0px;
  width: 168px;
}
.video-js .vjs-overlay-bottom-right {
  right: 1em;
  z-index: 10;
}
/* a wrapper element that tracks the mouse vertically */
.vjs-thumbnail-holder {
  position: absolute;
  left: -1000px;
}
/* the thumbnail image itself */
.vjs-thumbnail {
  position: absolute;
  left: -141px !important;
  bottom: 1.3em;
  opacity: 0;
  transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -mz-transition: opacity 0.2s ease;
}
/* fade in the thumbnail when hovering over the progress bar */
/* .fake-active is needed for Android only. It's removed on touchend/touchecancel */
.vjs-progress-control:hover .vjs-thumbnail,
.vjs-progress-control.fake-active .vjs-thumbnail,
.vjs-progress-control:active .vjs-thumbnail {
  opacity: 1;
}
/* ... but hide the thumbnail when hovering directly over it */
.vjs-progress-control:hover .vjs-thumbnail:hover,
.vjs-progress-control:active .vjs-thumbnail:active {
  opacity: 0;
}
.video-js .vjs-big-play-button {
  font-size: 8em;
  line-height: 1.5em;
  color: #ff9900;
  height: 1.63332em;
  width: 3em;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: none;
  background-color: unset;
  transition: all 0.4s;
}
.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -0.81666em;
  margin-left: -1.5em;
}
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border: 0;
  background-color: unset;
  transition: none;
}
.vjs-controls-disabled .vjs-big-play-button,
.vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-big-play-button,
.vjs-error .vjs-big-play-button {
  display: none;
}
.vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause
  .vjs-big-play-button {
  display: block;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.middle {
  display: flex;
  justify-content: center;
  align-content: center;
}
.container-vp {
  padding: 0;
  margin: 0;
}
.video-container {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  position: relative;
  align-items: center;
  height: 535px !important;
}
.video-chart-container {
  width: 100%;
  height: 100%;
}
.panZoom-container {
  width: 100%;
  height: 100%;
}
.overlay-video {
  width: 100%;
  height: 100%;
  /* height: 1000px; */
  position: absolute;
  /* background-color: yellow; */
  z-index: 30 !important;
  /* opacity: 0.5; */
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.video-controls-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  align-items: center;
}
.video-container:hover .video-controls-container {
  opacity: 1;
}
.video-container:hover .canvas {
  opacity: 0.5;
}
.video-container.paused .video-controls-container {
  opacity: 1;
}
.video-controls-container .controls {
  display: flex;
  align-items: center;
  color: white;
}
.video-controls-container .controls button {
  background: none;
  border: none;
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 150ms ease-in-out;
  color: white;
  align-items: center;
}
.video-controls-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
  width: 100%;
  aspect-ratio: 6/1;
  z-index: -1;
  pointer-events: none;
}
.video-controls-container .controls button:hover {
  opacity: 1;
}
.video-container.paused .pause-icon {
  display: none;
}
.video-container:not(.paused) .play-icon {
  display: none;
}
.volume-container {
  display: flex;
  align-items: center;
}
.duration-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-grow: 1;
}
.video-controls-container .controls button.wide {
  width: 50px;
}
.timeline-container {
  background-color: rgba(128, 128, 128, 0.5);
  height: 10px;
  margin-inline: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.marker-container-1 {
  width: 100%;
  height: 10px;
  position: absolute;
  overflow: hidden;
  top: -10px;
  cursor: pointer;
  opacity: 0.8;
  background-color: grey;
}

.marker-container-2 {
  width: 100%;
  height: 10px;
  position: absolute;
  overflow: hidden;
  bottom: 20px;
  cursor: pointer;
  opacity: 0.8;
  background-color: rgba(128, 128, 128, 0.5);
}

.marker-container-3 {
  width: 100%;
  height: 10px;
  position: absolute;
  overflow: hidden;
  top: -30px;
  cursor: pointer;
  opacity: 0.8;
  background-color: grey;
}

.marker-container-4 {
  width: 100%;
  height: 10px;
  position: absolute;
  overflow: hidden;
  top: -40px;
  cursor: pointer;
  opacity: 0.8;
  background-color: rgba(128, 128, 128, 0.5);
}

.marker-container-5 {
  width: 100%;
  height: 10px;
  position: absolute;
  overflow: hidden;
  top: -50px;
  cursor: pointer;
  opacity: 0.8;
  background-color: grey;
}

.timeline-container:hover .timeline {
  height: 20px;
}
.timeline::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc(100% - var(--preview-bar) * 100%);
}
.timeline::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc(100% - var(--progress-bar) * 100%);
  background-color: rgb(0, 144, 164);
}

.timeline .thumb-indicator {
  --scale: 0;
  position: absolute;
  transform: translateX(-50%) scale(var(--scale));
  height: 200%;
  top: -50%;
  left: calc(var(--progress-bar) * 100%);
  background-color: #0090a4;
  border-radius: 50%;
  transition: transform 150ms ease-in-out;
  aspect-ratio: 1 / 1;
}
.timeline .preview-img {
  position: absolute;
  height: 80px;
  aspect-ratio: 16 / 9;
  top: -1rem;
  transform: translate(-50%, -100%);
  left: calc(var(--preview-bar) * 100%);
  border-radius: 0.25rem;
  border: 2px solid white;
  display: none;
}
.thumbnail-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.wait-img {
  z-index: 15;
  position: absolute;
  height: 100%;
  width: 100%;
}
.video-container.scrubbing .thumbnail-img {
  display: block;
}
.video-container.scrubbing .preview-img,
.timeline-container:hover .preview-img {
  display: block;
}
.video-container.scrubbing .timeline::before,
.timeline-container:hover .timeline::before {
  display: block;
}
.video-container.scrubbing .thumb-indicator,
.timeline-container:hover .thumb-indicator {
  --scale: 1;
}
.video-container.scrubbing .timeline,
.timeline-container:hover .timeline {
  height: 100%;
}
/* ///////////////////// */
.show {
  display: block;
}
.hide {
  display: none;
}
.canvas {
  width: 101%;
  height: 95%;
  z-index: 20;
  position: absolute;
  transition: opacity 300ms ease-in-out;
  opacity: 0.3 !important;
  bottom: 410%;
  right: 0%;
  pointer-events: none;
}
#videoPlayer {
  width: 952px;
  height: 535.5px;
}

.mask-source {
  width: 100%;
  height: 100%;
  line-height: 100%;
  background-color: whitesmoke;
  position: absolute;
}

.media-abort {
  width: 100%;
  height: 100%;
  line-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.video-mask-img {
  display: inline-block;
  vertical-align: middle;
  max-width: 85%;
  max-height: 85%;
}
/* 
.right-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */
.toggle-switches {
  margin-top: -4%;
  margin-left: 10%;
  width: 80%;
}

.toggle-switches-events-calving {
  margin-top: -13%;
  margin-left: 8%;
}
.v-input--selection-controls {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.image_selector_icons_ac {
  height: 14px;
  padding-bottom: 4px;
  display: inline-block;
}
.image_selector_ac {
  text-align: center;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -6px;
  border-radius: 10px;
}
.flex-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: -20px;
  width: 100%;
  height: 100%;
}
.top-5-animals-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.top-5-animals {
  background-color: rgb(189, 189, 189);
  height: 82px;
}
.right-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-style {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  padding: 0 1px;
}

.custom-style::-webkit-scrollbar {
  width: 1px;
}

.custom-style::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-style:hover {
  scrollbar-color: lightgrey transparent;
}

.custom-style:hover::-webkit-scrollbar-thumb {
  background-color: lightgrey;
}

.frame-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.v-dialog {
  overflow: hidden;
}
.video-controls-container .controls {
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
}
.controls-left,
.controls-center {
  display: flex;
  align-items: center;
}
.red-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-left: 10px;
}
.blink {
  animation: blink 1s linear infinite;
}

.marker {
  position: absolute;
  z-index: 2;
  width: 3px;
  height: 10px;
  border-radius: 10%;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.range-slider {
  margin-bottom: -35px;
}

.toggle-switches-events {
  margin-left: 10%;
  margin-top: -2%;
}
.toggle-switches-visual {
  margin-top: -4%;
  margin-left: 5%;
}
.inference-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px; /* Adjust padding to be closer to the text */
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  font-weight: bold;
}

.inference-title-visuals {
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  margin-top: -10%;
}

.title-spacing {
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  margin-top: -15%;
  padding-bottom: 3%;
}

.label-container {
  margin-top: -10%;
}

/* .first-heading-inference {
  padding-top:1%;
} */
</style>
